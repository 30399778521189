import { alertRef, popperRef, popupRef, snackBarRef } from '@constants';
import { KAlertProps, KPopperProps, KPopupProps, KSnackBarProps } from 'uikit';

export default class UIUtils {
  static readonly alert = {
    open: (params: KAlertProps) => {
      alertRef.current?.open(params);
    },
    dismiss: () => {
      alertRef.current?.dismiss();
    }
  };

  static readonly popup = {
    open: (params: KPopupProps) => {
      popupRef.current?.open(params);
      popperRef.current?.dismiss();
    },
    dismiss: () => {
      popupRef.current?.dismiss();
      popperRef.current?.dismiss();
    }
  };

  static readonly popper = {
    open: (params: KPopperProps) => {
      popperRef.current?.open(params);
    },
    dismiss: () => {
      popperRef.current?.dismiss();
    }
  };

  static readonly snackBar = {
    open: (params: KSnackBarProps) => {
      snackBarRef.current?.open(params);
    },
    dismiss: () => {
      snackBarRef.current?.dismiss();
    }
  };
}
