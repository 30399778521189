import { BaseResponse } from '@dto';
import { UseCUDMutationOptions, UseGenericMutationOptions } from '@hook-dto';
import APIManager from '@services';
import { generatePath } from 'react-router-dom';
import { UIUtils } from 'utils';

import { useMutationEnhancer } from './useRequestProcessor';

export const useGenericMutationEnhancer = <T = unknown>(
  options: UseGenericMutationOptions
) => {
  const {
    endPoint,
    method,
    // webTable,
    // dependentWebTable,
    // alwaysReturnData,
    // returnedData,
    // isPage,
    onSuccess,
    noDismiss,
    ...rest
  } = options;

  return useMutationEnhancer<BaseResponse<any>, T>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: generatePath(endPoint, data as any),
        method,
        showToast: (data as any)?.showToast ?? true,
        body: data
      });

      return res;
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        // if (webTable) {
        //   tableRef?.[webTable]?.init?.();
        // }
        // dependentWebTable?.forEach(i => {
        //   tableRef?.[i]?.init?.();
        // });
        if (!noDismiss) {
          UIUtils.popup.dismiss();
        }
        onSuccess?.({ data: res.data, variables, options });
      }
    },
    ...rest
  });
};

export const useCUDMutationEnhancer = <T = unknown>(
  options: UseCUDMutationOptions
) => {
  const createMutation = useGenericMutationEnhancer<Omit<T, 'id'>>({
    ...options,
    endPoint: `${options.endPoint}/Add`,
    method: 'POST'
  });

  const updateMutation = useGenericMutationEnhancer<T>({
    ...options,
    endPoint: `${options.endPoint}/Update`,
    method: 'POST'
  });

  const deleteMutation = useGenericMutationEnhancer<any>({
    ...options,
    method: 'DELETE',
    endPoint: `${options.endPoint}/Delete`,
    invalidateQueryKeys: options.deleteWithoutRefetch
      ? []
      : options.invalidateQueryKeys
  });

  const deleteRangeMutation = useGenericMutationEnhancer<any>({
    ...options,
    method: 'DELETE',
    endPoint: `${options.endPoint}/DeleteRange`,
    invalidateQueryKeys: options.deleteWithoutRefetch
      ? []
      : options.invalidateQueryKeys
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    deleteRangeMutation,
    isModifying: createMutation.isPending || updateMutation.isPending,
    isDeleting: deleteMutation.isPending || deleteRangeMutation.isPending
  };
};
