import borders from '../../base/borders';
import { baseColors } from '../../base/colors';
import pxToRem from '../../functions/pxToRem';

const { radius } = borders;

const root = {
  // textTransform: 'uppercase',
  borderRadius: radius.x,
  padding: `${pxToRem(5)} ${pxToRem(10)}`,

  '&.Mui-disabled': {
    backgroundColor: baseColors.backgroundDisabled,
    color: baseColors.disabled,
    '& .material-icon, .material-icons-round, svg': {
      fontSize: '1.25rem !important'
    }
  }
};

export default root;
