import { E_RACE_MODES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { KColors, KContainer, KLabel, KSpacingValue, KViewProps } from 'uikit';

interface IProps extends KViewProps {
  remainingTime: number | undefined;
}

const RemainingTime = ({ remainingTime, ...rest }: IProps) => {
  const ref = useRef<any>(null);

  const { gameInfo } = useGameContext();

  const totalTime = useMemo(() => {
    if (gameInfo?.raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id) {
      return (gameInfo?.min ?? 0) * 60 + (gameInfo?.sec ?? 0);
    }
    return 0;
  }, [gameInfo?.min, gameInfo?.raceMode, gameInfo?.sec]);

  const remainingPercentage = useMemo(
    () => Math.floor(((remainingTime ?? totalTime) / totalTime) * 100),
    [remainingTime, totalTime]
  );

  useEffect(() => {
    if (remainingTime !== undefined) {
      ref.current.style.transform = `scaleX(${remainingTime / totalTime})`;
    }
  }, [remainingTime, totalTime]);

  return (
    <KContainer.View position="relative" {...rest}>
      <KContainer.View className="absolute -top-[32px] right-0">
        <KContainer.View
          width={50}
          height={20}
          br="round"
          background="#B3F5E9"
          center
        >
          <KLabel.Text typo="TextXsNormal" color="#00694A">
            {remainingPercentage}%
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View
          width={0}
          height={0}
          // background="#B3F5E9"
          marginL="1.25rem"
          brTW={7}
          brTC="#B3F5E9"
          brLW={5}
          brRW={5}
          brLC="transparent"
          brRC="transparent"
        ></KContainer.View>
      </KContainer.View>

      <KContainer.View
        brW={3}
        br="round"
        brC="#00694a"
        background={KColors.white}
        height={26}
        width={`calc(100% - ${KSpacingValue['1.75rem'] * 2})`}
        overflow="hidden"
      >
        <KContainer.View
          ref={ref}
          brTL="round"
          brBL="round"
          brTR={
            !remainingTime || remainingTime === totalTime ? 'round' : undefined
          }
          brBR={
            !remainingTime || remainingTime === totalTime ? 'round' : undefined
          }
          width="100%"
          height="100%"
          background="#01DFB7"
          style={{
            transform: 'scaleX(1)',
            transformOrigin: 'left',
            transition: 'transform 0.1s'
          }}
        />
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(RemainingTime);
