class Prototype {
  string = {
    trimSpaces: (v: string) => v.replace(/\s/g, '')
  };

  number = {};

  date = {};

  core = {
    isNullOrUndefined: (v: any) =>
      v === null || v === undefined || v === 'undefined'
  };
}

export default new Prototype();
