import { IS_TEACHER } from '@constants';
import { KContainer, KDims } from 'uikit';

const withMPadding = (WrappedComponent: any) => {
  const WithMPadding = (props: any) => {
    const isMobile = KDims.isMobile();

    return (
      <KContainer.View
        paddingH={isMobile ? '1.25rem' : IS_TEACHER ? undefined : '2rem'}
      >
        <WrappedComponent {...props} />
      </KContainer.View>
    );
  };

  WithMPadding.displayName = `withMPadding(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return WithMPadding;
};

export { withMPadding };
