import { UseResolverFormProps } from '@hook-dto';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';

export const useResolverForm = <T extends FieldValues>({
  schema,
  configs
}: UseResolverFormProps<T>): UseFormReturn<T> => {
  return useForm<T>({
    shouldUnregister: false,
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    delayError: 150,
    shouldFocusError: true,
    ...configs,
    resolver: yupResolver(schema) as any
  });
};
