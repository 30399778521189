import { IS_TEACHER } from '@constants';
import { TABLE_STATUSES, WORD_STATUSES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import { useStudentInGameInfo, useTeacherInGameInfo } from 'hooks';
import React, { memo, useMemo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer } from 'uikit';

import InGameImage from '../InGame.Image';

interface IProps {
  status?: TABLE_STATUSES;
}

const WaitingForStart = ({ status }: IProps) => {
  const { onStartWord } = useGameContext();

  const isWaiting = status === TABLE_STATUSES.WAITING;

  const { runningTeam } = useTeacherInGameInfo();
  const { opposingTeam } = useStudentInGameInfo();

  const curTeam = IS_TEACHER ? runningTeam : opposingTeam;

  const wordIndex = useMemo(() => {
    if (curTeam) {
      const words = curTeam.words;
      const index = words.findIndex(
        w => w.status === WORD_STATUSES.NOT_STARTED_YET
      );
      if (index !== -1) {
        return index + 1;
      }
    }
    return 1;
  }, [curTeam]);

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri="waiting-for-start.webp"
        background={KColors.primary.normal}
      >
        <KButton.Solid
          marginT="3rem"
          title={
            isWaiting
              ? `${trans('common.start_word') + ` ${wordIndex}`}`
              : trans('common.waiting_for_students')
          }
          kind="gray"
          background={KColors.white}
          textColor={KColors.primary.normal}
          onPress={onStartWord}
          disabled={!isWaiting}
        />
      </InGameImage>
    </KContainer.View>
  );
};

WaitingForStart.displayName = 'Containers.InGame.WaitingForStart';

export default memo(WaitingForStart);
