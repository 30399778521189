import { useFetchFolderList, useFetchGameList } from 'hooks';
import flatten from 'lodash/flatten';
import { useMemo } from 'react';

export const useDashboardGameData = () => {
  const { data: folderList = [] } = useFetchFolderList();

  const { data: gameList = [] } = useFetchGameList();

  const dataReturn = useMemo(() => {
    const _data = (gameList ?? []).map(g => {
      const folder = (folderList ?? []).find(f => f.id === g.id);
      if (folder) {
        g.color = folder.color;
      }
      return g;
    });

    return _data;
  }, [folderList, gameList]);

  const gameData = useMemo(() => {
    return flatten((gameList ?? []).map(g => g.games));
  }, [gameList]);

  return { data: dataReturn, gameData };
};
