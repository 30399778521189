import { IconX } from '@assets/icons/components/IconX';
import { AlertTransitionConfigs } from '@constants/transitions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import { TransitionProps } from '@mui/material/transitions';
import { WithAlertDialogProps } from '@ui';
import { useBoolean } from 'hooks/core/useBoolean';
import React, {
  forwardRef,
  memo,
  useId,
  useImperativeHandle,
  useState,
  useMemo
} from 'react';
import trans from 'translation';
import { KColors, KContainer, KLabel, KAlertProps, KButtonProps } from 'uikit';
import { KButtonBase } from 'uikit/uikit/Button/Base';

const { Element, props: elementProps } = AlertTransitionConfigs;

const DEFAULT_KIND: KButtonProps['kind'] = 'primary';
const DEFAULT_SIZE: KButtonProps['size'] = 'md';
const DEFAULT_WEIGHT: KButtonProps['weight'] = 'medium';

export const TransitionComponent = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Element {...elementProps} ref={ref} {...props} />;
});

const PaperComponent = memo((props: PaperProps) => {
  return (
    <Paper
      {...props}
      sx={{ overflowY: 'visible !important', borderRadius: '8px' }}
    />
  );
});

const KAlert = forwardRef<WithAlertDialogProps>((_, ref) => {
  const { value, setTrue, setFalse } = useBoolean(false);

  const [data, setData] = useState<KAlertProps | undefined>();

  const { buttons, message, title, touchOutsideToDismiss } = data || {};

  const randomId = useId();

  const id = randomId.replace(/:/g, '');

  useImperativeHandle(
    ref,
    () => ({
      open: (payload: KAlertProps) => {
        setData(payload);
        setTrue();
      },
      dismiss: setFalse
    }),
    [setFalse, setTrue]
  );

  const isButtonEmpty = !buttons || buttons?.length === 0;

  const mButtons = useMemo(
    () =>
      isButtonEmpty
        ? [
            {
              title: trans('common.ok'),
              kind: DEFAULT_KIND,
              weight: DEFAULT_WEIGHT,
              size: DEFAULT_SIZE
            } as KButtonProps
          ]
        : buttons,
    [buttons, isButtonEmpty]
  );

  if (!id) {
    return null;
  }

  return (
    <Dialog
      key={id}
      aria-labelledby={`title-${id}`}
      maxWidth="sm"
      fullWidth
      open={value}
      TransitionComponent={TransitionComponent}
      PaperComponent={PaperComponent}
      onClose={touchOutsideToDismiss ? setFalse : undefined}
    >
      <DialogTitle sx={{ p: 0, paddingY: '12px' }}>
        <KContainer.View row alignItems>
          <KContainer.View width={24} />
          <KContainer.View flex>
            {title && (
              <KContainer.View center>
                {typeof title === 'string' ? (
                  <KLabel.Text typo={'H2'} textAlign>
                    {title}
                  </KLabel.Text>
                ) : (
                  title
                )}
              </KContainer.View>
            )}
          </KContainer.View>
          <IconButton onClick={setFalse}>
            <IconX className="w-[24px] h-[24px]" />
          </IconButton>
        </KContainer.View>
      </DialogTitle>

      <DialogContent sx={{ mb: '14px', mt: '30px', justifyContent: 'center' }}>
        <KContainer.View alignItems>
          {typeof message === 'string' ? (
            <KLabel.Text color={KColors.black}>{message}</KLabel.Text>
          ) : typeof message === 'function' ? (
            message(setFalse)
          ) : (
            message
          )}
        </KContainer.View>
      </DialogContent>

      <DialogActions sx={{ marginX: '16px', alignItems: 'center' }}>
        {mButtons.map((button, idx) => {
          const {
            size = DEFAULT_SIZE,
            kind = DEFAULT_KIND,
            weight = DEFAULT_WEIGHT
          } = button;
          const onPress = (e: any) => {
            button.onPress?.(e);
            setFalse();
          };

          return (
            <KButtonBase
              key={`alert-button-${idx}`}
              {...button}
              onPress={onPress}
              size={size}
              weight={weight}
              kind={kind}
            />
          );
        })}
      </DialogActions>
    </Dialog>
  );
});

export default memo(KAlert);
