import { PopoverTransitionConfigs } from '@constants/transitions';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import { TransitionProps as TTransitionProps } from '@mui/material/transitions';
import { WithPopperProps } from '@ui';
import { useBoolean } from 'hooks/core/useBoolean';
import React, {
  forwardRef,
  memo,
  useState,
  useImperativeHandle,
  useEffect
} from 'react';
import { KPopperProps } from 'uikit';

const { Element, props: elementProps } = PopoverTransitionConfigs;

export const TransitionComponent = React.forwardRef(function Transition(
  props: TTransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Element {...elementProps} ref={ref} {...props} />;
});

const KPopper = forwardRef<WithPopperProps>((_, ref) => {
  const { value, setTrue, setFalse } = useBoolean(false);

  const {
    value: isHidden,
    setTrue: setIsHiddenTrue,
    setFalse: setIsHiddenFalse
  } = useBoolean(false);

  const [data, setData] = useState<KPopperProps | undefined>(undefined);

  // useEffect(() => {
  //   if (isHidden) {
  //     setData(undefined);
  //   }
  // }, [isHidden]);

  const {
    content,
    touchOutsideToDismiss,
    sx,
    transitionType,
    onVisibilityChanged,
    ...rest
  } = data || {};

  useEffect(() => {
    onVisibilityChanged?.(value);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, [value]);

  useImperativeHandle(
    ref,
    () => ({
      open: (payload: KPopperProps) => {
        setData(payload);
        setTrue();
      },
      dismiss: setFalse
    }),
    [setFalse, setTrue]
  );

  const Transition = transitionType === 'fade' ? Fade : Grow;

  const renderer = (
    <Popper
      {...rest}
      sx={{ zIndex: 1, ...sx }}
      transition
      id="transition-popper"
      open={value}
      keepMounted={false}
    >
      {({ TransitionProps }) => (
        <Transition
          {...TransitionProps}
          timeout={250}
          onExited={setIsHiddenTrue}
          onEntered={setIsHiddenFalse}
          unmountOnExit
        >
          <div>{content?.(setFalse)}</div>
        </Transition>
      )}
    </Popper>
  );

  if (!data) {
    return null;
  }

  if (touchOutsideToDismiss) {
    return (
      <ClickAwayListener
        disableReactTree
        onClickAway={() => {
          if (!isHidden) {
            setFalse();
          }
        }}
      >
        {renderer}
      </ClickAwayListener>
    );
  }
  return renderer;
});

export default memo(KPopper);
