import React, { forwardRef, memo } from 'react';

import TextField from './TextField';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KLabel from '../Label';
import { KInputProps } from '../types';

interface KSelectionProps extends KInputProps {
  color?: string;
}

const KSelection = forwardRef<HTMLInputElement, KSelectionProps>(
  (props, ref) => {
    const { sx, color = KColors.black, placeholder, ...rest } = props;

    return (
      <KContainer.View position="relative">
        <TextField
          ref={ref as any}
          {...rest}
          sx={{
            background: rest.disabled ? '#e2e8f0' : KColors.white,
            ...sx
          }}
          InputLabelProps={{
            sx: {
              color,
              '&.Mui-focused': {
                color
              }
            }
          }}
          InputProps={{
            sx: {
              '& > fieldset': {
                borderWidth: 1,
                borderColor: '#e2e8f0'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: KColors.primary.normal
              },
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderWidth: 0
              }
            }
          }}
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  maxHeight: 181
                }
              }
            }
          }}
        />

        {!rest.value && placeholder && (
          <KContainer.View
            position="absolute"
            className="top-[35px] left-[12px]"
          >
            <KLabel.Text typo="TextMdNormal" color="#8B8C9F">
              {placeholder}
            </KLabel.Text>
          </KContainer.View>
        )}
      </KContainer.View>
    );
  }
);

KSelection.defaultProps = {
  br: '2x'
};

export default memo(KSelection);
