import { usePrevious } from '@dwarvesf/react-hooks';
import isEmpty from 'lodash/isEmpty';
import React, {
  ComponentType,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useTransition
} from 'react';

import KColors from '../../constants/colors';
import { useMount } from '../../hooks';
import KContainer from '../Container';
import KLabel from '../Label';
import { KTabProps } from '../types';
import { KSpacingValue } from '../Typography';

const TAB_WIDTH = 80;

const KTabBase = memo(
  forwardRef((props: KTabProps, ref) => {
    const {
      tabs = [],
      initialIndex,
      onChangeTab,
      tintColor,
      activeBackground,
      textColor,
      inactiveTextColor,
      spacing = '1rem',
      divider,
      transition,
      kind = 'indicator',
      isEqualWidth,
      disabled,
      ...rest
    } = props;

    const indicatorRef = React.createRef<HTMLDivElement>();
    const tabRef = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));

    const [tabIndex, setTabIndex] = useState(initialIndex ?? 0);

    const prevIndex = usePrevious(initialIndex);

    const [, startTransition] = useTransition();

    useMount(() => {
      if (!isEmpty(tabs) && tabIndex >= 0 && indicatorRef.current) {
        const tabWidth =
          tabRef.current?.[tabIndex]?.current?.offsetWidth ?? TAB_WIDTH;
        indicatorRef.current.style.width = `${tabWidth}px`;
      }
    });

    useEffect(() => {
      if (initialIndex && initialIndex !== prevIndex) {
        setTabIndex(initialIndex);
      }
    }, [initialIndex, prevIndex]);

    const onChangeWrapper = useCallback(
      (index: number, noAction: boolean = false) => {
        if (index !== tabIndex) {
          if (indicatorRef.current) {
            const tabWidth =
              tabRef.current?.[index]?.current?.offsetWidth ?? TAB_WIDTH;

            let width = 0;
            if (index > 0) {
              for (let i = 0; i < index; i++) {
                width +=
                  (tabRef.current?.[i]?.current?.offsetWidth ?? 0) +
                  (spacing ? KSpacingValue[spacing] : 0);
              }
            }
            indicatorRef.current.style.transition =
              'transform 0.5s, width 0.5s';
            indicatorRef.current.style.transform = `translate(${width}px, 0px)`;
            indicatorRef.current.style.width = `${tabWidth}px`;
          }

          setTabIndex(index);

          if (transition) {
            startTransition(() => {
              if (!noAction) {
                onChangeTab?.(index);
              }
            });
          } else {
            if (!noAction) {
              onChangeTab?.(index);
            }
          }
        }
      },
      [indicatorRef, onChangeTab, spacing, tabIndex, transition]
    );

    useImperativeHandle(ref, () => ({
      onChange: onChangeWrapper
    }));

    const renderSeparator = useMemo(() => {
      if (divider) {
        return (
          <KContainer.View
            width={1}
            height={25}
            background="#E2E8F0"
            marginB="0.25rem"
            marginH="0.75rem"
          />
        );
      }

      return <KContainer.View width={KSpacingValue[spacing]} />;
    }, [divider, spacing]);

    return (
      <KContainer.Card
        dp="flex"
        row
        alignItems
        paddingV={kind === 'background' ? 0 : '0.5rem'}
        marginV="0.5rem"
        height={50}
        noShadow
        overflow
        overflowY="hidden"
        br={0}
        brBC="#E2E8F0"
        {...rest}
      >
        {tabs.map((i, idx) => {
          const { key, label } = i;
          const isActive = idx === tabIndex;
          const color = isActive ? textColor : inactiveTextColor;

          return (
            <React.Fragment key={key}>
              {idx !== 0 && renderSeparator}

              <KContainer.Touchable
                ref={tabRef.current[idx]}
                paddingV="0.5rem"
                paddingH="0.75rem"
                minW={TAB_WIDTH}
                onPress={onChangeWrapper.bind(null, idx, false)}
                dp="flex"
                center
                disabled={disabled || i.disabled}
                height={kind === 'background' ? '100%' : undefined}
                background={
                  isActive && kind === 'background'
                    ? activeBackground
                    : KColors.transparent
                }
                flex={isEqualWidth ? 1 : undefined}
              >
                <KLabel.Text
                  typo={isActive ? 'TextXNmBold' : 'TextXNmNormal'}
                  color={color}
                  // textTransform="uppercase"
                  textAlign
                >
                  {label}
                </KLabel.Text>
              </KContainer.Touchable>
            </React.Fragment>
          );
        })}

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={kind === 'indicator'}>
            <KContainer.View
              ref={indicatorRef}
              style={{
                position: 'absolute',
                bottom: 0,
                transition: 'transform 500ms'
              }}
              height={2}
              width={TAB_WIDTH}
              background={tintColor}
            />
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KContainer.Card>
    );
  })
);

(KTabBase as ComponentType<KTabProps>).defaultProps = {
  // background: KColors.white
};

const KTabSolid = memo(
  forwardRef((props: KTabProps, ref) => {
    return (
      <KTabBase
        background={KColors.white}
        tintColor={KColors.primary.normal}
        textColor={KColors.primary.normal}
        inactiveTextColor={KColors.black}
        ref={ref}
        {...props}
      />
    );
  })
);

const KTabTransparent = memo(
  forwardRef((props: KTabProps, ref) => {
    return (
      <KTabBase
        background={KColors.transparent}
        tintColor={KColors.primary.normal}
        textColor={KColors.black}
        inactiveTextColor={KColors.opacity.black[54]}
        ref={ref}
        {...props}
      />
    );
  })
);

(KTabBase as ComponentType<KTabProps>).displayName = 'KTabBase';
(KTabSolid as ComponentType<KTabProps>).displayName = 'KTabSolid';
(KTabTransparent as ComponentType<KTabProps>).displayName = 'KTabTransparent';

export { KTabBase, KTabSolid, KTabTransparent };
