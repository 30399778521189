import { IconCheck } from '@assets/icons/components/IconCheck';
import { IconCopyRight } from '@assets/icons/components/IconCopyRight';
import { IconFolderSearch } from '@assets/icons/components/IconFolderSearch';
import { IconGame } from '@assets/icons/components/IconGame';
import { IconLinkBroken } from '@assets/icons/components/IconLinkBroken';
import { IconMoreVertical } from '@assets/icons/components/IconMoreVertical';
import { IconPencil } from '@assets/icons/components/IconPencil';
import { MUTATION_KEYS } from '@constants';
import { IGameDto } from '@dto';
import loadable from '@loadable/component';
import { useDuplicateGame, useMoveGame } from 'hooks';
import { useBoolean } from 'hooks/core/useBoolean';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KInput,
  KLabel,
  KViewProps,
  generateElementId
} from 'uikit';
import { UIUtils } from 'utils';

const MoveToFolder = loadable(() => import('./MoveToFolder'));

interface IProps extends KViewProps {
  id?: string;
  item: IGameDto;
}

const EL_TAG = 'game-item';

const GameItem = ({ item, id, ...rest }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [newLabel, setNewLabel] = useState<string>(item.label ?? '');

  const { value: isView, toggle } = useBoolean(true);

  // const onDismiss = useCallback(() => {
  //   UIUtils.popper.dismiss();
  // }, []);

  const onCopyCode = useCallback(() => {
    navigator.clipboard.writeText(item.code).then(() => {
      UIUtils.snackBar.open({
        message: trans('common.copied'),
        status: 'success'
      });
    });
  }, [item.code]);

  const onShareGame = useCallback(() => {
    UIUtils.popper.dismiss();
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconLinkBroken
            stroke={KColors.primary.normal}
            fill={KColors.white}
          />
        )
      },
      maxWidth: 'xs',
      title: trans('common.share_id_game'),
      content: (
        <KContainer.View row alignItems>
          <KInput.TextField
            name="code"
            label={trans('common.the_code_id_game')}
            value={item.code}
            readOnly
          />

          <KContainer.View alignSelf="flex-end" marginL="1rem">
            <KContainer.Touchable
              size={50}
              center
              background="#01dfb7"
              br="round"
              onPress={onCopyCode}
            >
              <IconCopyRight
                stroke={KColors.white}
                fill={KColors.transparent}
              />
            </KContainer.Touchable>
          </KContainer.View>
        </KContainer.View>
      ),
      buttons: [
        {
          variant: 'outline',
          title: trans('common.cancel')
        }
      ]
    });
  }, [item.code, onCopyCode]);

  const onAssignGame = useCallback(() => {
    UIUtils.popper.dismiss();
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconFolderSearch
            stroke={KColors.primary.normal}
            fill={KColors.transparent}
          />
        )
      },
      maxWidth: 'xs',
      title: trans('common.move_assign_to'),
      content: <MoveToFolder data={item} />,
      mutationKey: [MUTATION_KEYS.moveGame]
    });
  }, [item]);

  const onEditGame = useCallback(() => {
    UIUtils.popper.dismiss();
    navigate(`game/${item.id}`);
  }, [item.id, navigate]);

  const { mutate } = useMoveGame();

  const { mutate: mutateDuplicate } = useDuplicateGame();

  const onEditLabel = useCallback(() => {
    if (!isView && newLabel !== item.label) {
      mutate({ ...item, label: newLabel });
    }
    toggle();
  }, [isView, item, mutate, newLabel, toggle]);

  const onDuplicate = useCallback(() => {
    UIUtils.popper.dismiss();
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconLinkBroken
            stroke={KColors.primary.normal}
            fill={KColors.white}
          />
        )
      },
      maxWidth: 'xs',
      title: trans('common.duplicate_game'),
      content: trans('common.duplicate_game_message'),
      buttons: [
        {
          variant: 'outline',
          title: trans('common.cancel')
        },
        {
          key: 'main',
          variant: 'solid',
          title: trans('common.duplicate'),
          onPress: () => mutateDuplicate(item.code),
          withDismiss: true
        }
      ],
      mutationKey: [MUTATION_KEYS.duplicateGame]
    });
  }, [item.code, mutateDuplicate]);

  const eData = useMemo(() => {
    return [
      {
        title: 'common.duplicate',
        onPress: onDuplicate
      },
      {
        title: 'common.edit',
        onPress: onEditGame
      },
      {
        title: 'common.share',
        onPress: onShareGame
      },
      {
        title: 'common.move_assign_to',
        onPress: onAssignGame
      }
    ];
  }, [onAssignGame, onDuplicate, onEditGame, onShareGame]);

  const onPress = useCallback(() => {
    UIUtils.popper.open({
      anchorEl: ref.current,
      placement: 'left-start',
      id: generateElementId(`${item.id}`, EL_TAG),
      content: () => (
        <KContainer.View
          background={KColors.white}
          brW={1}
          brC="#e2e8f0"
          br="3x"
          minW={150}
          paddingV="0.75rem"
          paddingH="0.5rem"
          dp="flex"
        >
          {eData.map(e => {
            return (
              <KButton.Transparent
                key={`game-${item.id}-${e.title}`}
                title={trans(e.title)}
                kind="gray"
                onPress={e.onPress}
                height={36}
                alignItems="flex-start"
                width="100%"
              />
            );
          })}
        </KContainer.View>
      ),
      sx: { zIndex: 1301 },
      touchOutsideToDismiss: true
    });
  }, [eData, item.id]);

  return (
    <KContainer.View
      paddingV="0.75rem"
      background={KColors.white}
      row
      alignItems
      id={generateElementId(id, EL_TAG)}
      {...rest}
    >
      <IconGame stroke="#8b8c9f" fill="#e7e7e7" />

      <KContainer.View flex marginH="1rem">
        {isView ? (
          <KContainer.Touchable
            width="fit-content"
            onPress={onEditGame}
            noHover
          >
            <KLabel.Text numberOfLines={2} typo="TextXNmNormal">
              {item.label}
            </KLabel.Text>
          </KContainer.Touchable>
        ) : (
          <KInput.TextField
            name="newGameLabel"
            value={newLabel}
            onChange={e => setNewLabel(e.target.value)}
          />
        )}
      </KContainer.View>

      <KContainer.Touchable
        onPress={onEditLabel}
        size={24}
        center
        br="round"
        avoidParentPress
        marginR="0.5rem"
      >
        {isView ? (
          <IconPencil stroke="#8B8C9F" />
        ) : (
          <IconCheck
            stroke={KColors.primary.normal}
            fill={KColors.transparent}
          />
        )}
      </KContainer.Touchable>

      <KContainer.Touchable
        ref={ref}
        onPress={onPress}
        size={24}
        center
        br="round"
        avoidParentPress
      >
        <IconMoreVertical fill="#8B8C9F" />
      </KContainer.Touchable>
    </KContainer.View>
  );
};

(GameItem as React.ComponentType<any>).displayName = EL_TAG;

export default memo(GameItem);
