import { WithChildren } from '@ui';
import cx from 'classnames';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const DrawerContent = (props: WithChildren<Props>) => {
  const { className, children, ...rest } = props;

  return (
    <div {...rest} className={cx(className)}>
      {children}
    </div>
  );
};
