import { IconGoogle } from '@assets/icons/components/IconGoogle';
import { useGoogleLogin } from '@react-oauth/google';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KButton } from 'uikit';

const GoogleButton = () => {
  const signIn = useGoogleLogin({
    // onSuccess: params => {
    //   console.log('params', params);
    //   // mutate({
    //   //   accessToken: access_token
    //   // });
    // },
    // onError: ({ error_description }) => {
    //   UIUtils.snackBar.open({
    //     message: error_description as any,
    //     status: 'danger'
    //   });
    // }

    ux_mode: 'redirect',
    redirect_uri: process.env.REACT_APP_TEACHER_URL,
    state: 'sign-in',
    flow: 'auth-code'
  });

  const onSignIn = useCallback(() => signIn(), [signIn]);

  return (
    <KButton.Outline
      title={trans('common.google_button')}
      onPress={onSignIn}
      br={'2x'}
      brC="#e2e8f0"
      tintColor="#334155"
      weight="normal"
      startIcon={<IconGoogle />}
      paddingH={'1.5rem'}
      enhanceStyle={{ width: '100%' }}
    />
  );
};

export default memo(GoogleButton);
