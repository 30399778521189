import { ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from '@constants';
import { IFolderDto } from '@dto';
import APIManager from '@services';

import { useUser } from '../auth';
import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchFolderList = () => {
  const { user } = useUser();

  return useQueryEnhancer<IFolderDto[]>({
    queryKey: [QUERY_KEYS.folderList],
    queryFn: () =>
      APIManager.request({
        url: ENDPOINTS.folder('GetFolders')
      }),
    enabled: !!user,
    initialData: [],
    placeholderData: []
  });
};

export const useCUDFolder = () => {
  return useCUDMutationEnhancer<IFolderDto>({
    endPoint: ENDPOINTS.folder(),
    invalidateQueryKeys: [[QUERY_KEYS.folderList], [QUERY_KEYS.gameList]],
    mutationKey: [MUTATION_KEYS.cudGame]
  });
};
