import { authRef } from '@constants';
import AppValidation from 'core/validation';
import { useResolverForm, useSignIn } from 'hooks';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KDims,
  KForm,
  KGrid,
  KInput,
  KLabel
} from 'uikit';
import * as yup from 'yup';

interface IProps {
  onForgotPassword: () => void;
}

interface IFormData {
  email: string;
  password: string;
}

const schema = () =>
  yup.object().shape({
    email: AppValidation.required(),
    password: AppValidation.required()
  });

const Login = ({ onForgotPassword }: IProps) => {
  const { mutate, isPending } = useSignIn();

  const isMobile = KDims.isMobile();

  const methods = useResolverForm<IFormData>({
    schema: schema(),
    configs: {
      defaultValues: {
        email: '',
        password: ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { email, ...rest } = data;
      const mParams = {
        ...rest,
        userName: email
      };
      mutate(mParams);
    },
    [mutate]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container columnSpacing={0} spacing={4}>
        <KGrid.Item xs={12}>
          <Controller
            name="email"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('common.email')}
                  placeholder={trans('common.email_placeholder')}
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12} className="relative">
          <Controller
            name="password"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('common.password')}
                  placeholder={trans('common.password_placeholder')}
                  inputType="password"
                  required
                  message={error?.message}
                />
              );
            }}
          />

          <KContainer.View className="absolute top-2 right-0">
            <KLabel.Text
              color={KColors.primary.normal}
              onPress={() => onForgotPassword()}
            >
              {trans('common.forgot_password')}
            </KLabel.Text>
          </KContainer.View>
        </KGrid.Item>

        <KGrid.Item xs={12} className="text-center">
          <KLabel.Paragraph textAlign>
            <KLabel.RichText
              richTextOptions={{
                i18nKey: 'common.login_agree_term',
                components: {
                  bold: <KLabel.Text typo="TextNmBold" underline />
                }
              }}
            />
          </KLabel.Paragraph>
        </KGrid.Item>

        {!isMobile && <KContainer.View height={156} />}

        <KGrid.Item xs={12}>
          <KButton.Solid
            title={trans('common.log_in')}
            type="submit"
            // br="round"
            weight="normal"
            isLoading={isPending}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KButton.Outline
            title={trans('common.create_an_account')}
            // br="round"
            weight="normal"
            onPress={() => authRef.current?.setTabIndex(1)}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(Login);
