import { authRef } from '@constants';
import { useDisclosure } from '@dwarvesf/react-hooks';
import AppValidation from 'core/validation';
import dayjs from 'dayjs';
import { useResolverForm, useSignUp } from 'hooks';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KForm,
  KGrid,
  KInput,
  KPicker,
  KListItem,
  APP_NAME
} from 'uikit';
import * as yup from 'yup';

import { PrivacySheet } from './Privacy';

interface IFormData {
  email: string;
  password: string;
  birthday: any;
  username: string;
  checked: boolean;
}

const schema = yup.object().shape({
  birthday: AppValidation.requiredDate(),
  username: AppValidation.required(),
  email: AppValidation.required().email(),
  password: AppValidation.required(),
  checked: AppValidation.requiredBool()
});

const Register = () => {
  const { mutate, isPending } = useSignUp();

  const { isOpen, onClose } = useDisclosure();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      defaultValues: {
        email: '',
        password: '',
        birthday: new Date(),
        username: '',
        checked: false
      },
      mode: 'onChange',
      reValidateMode: 'onChange'
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { username, ...rest } = data;
      const mParams = {
        ...rest,
        isTeacher: true,
        userName: username,
        birthday: dayjs(new Date(data.birthday)).unix()
      };
      mutate(mParams);
    },
    [mutate]
  );

  return (
    <>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container columnSpacing={0} spacing={4}>
          <KGrid.Item xs={12}>
            <Controller
              name="birthday"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Date
                    {...field}
                    label={trans('common.birthday')}
                    required
                    message={error?.message}
                    selected={field.value}
                    maxDate={new Date()}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="email"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('common.email')}
                    placeholder={'name@email.com'}
                    inputType="email"
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="username"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('common.username')}
                    placeholder={'andrew123'}
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="password"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Password
                    {...field}
                    label={trans('common.password')}
                    placeholder={trans('placeholder.password')}
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="checked"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={`I have read and agree to ${APP_NAME}'s Terms and Conditions.`}
                    // alignItems="flex-start"
                    checked={field.value}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KButton.Solid
              title={trans('common.get_started')}
              type="submit"
              // br="round"
              weight="normal"
              disabled={!methods.formState.isValid}
              isLoading={isPending}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KButton.Outline
              title={trans('common.already_have_an_account')}
              // br="round"
              weight="normal"
              onPress={() => authRef.current?.setTabIndex(0)}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KForm>

      <PrivacySheet isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default memo(Register);
