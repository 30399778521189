class AppEvent {
  subscribe = (name: string, cb: EventListenerOrEventListenerObject) => {
    document.addEventListener(name, cb);
  };

  unsubscribe = (name: string, cb: EventListenerOrEventListenerObject) => {
    document.removeEventListener(name, cb);
  };

  dispatch = (name: string, data?: any) => {
    const event = new CustomEvent(name, { detail: data });
    document.dispatchEvent(event);
  };
}

export default new AppEvent();
