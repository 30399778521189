import { AVAILABLE_GAMES, GAMES } from '@constants/gameConfigs';
import { SelectionItem } from '@dto';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PageHeader from 'containers/PageHeader';
import { withMPadding } from 'hocs/withMPadding';
import { useFetchFolderList, useFetchGame } from 'hooks';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import trans from 'translation';
import { KColors, KContainer, KLabel } from 'uikit';

import CreateGameCategoriesForm from './Game.CategoriesForm';
import CreateGameHotseatForm from './Game.HotseatForm';
import CreateGameJumbleForm from './Game.JumbleForm';
import CreateGameSnapForm from './Game.SnapForm';
import CreateGameTabooForm from './Game.TabooForm';

const CreateGame = () => {
  const { id } = useParams();

  const { data: game } = useFetchGame(id);

  const [activeTab, setActiveTab] = useState(AVAILABLE_GAMES[0].id);

  useFetchFolderList();

  useEffect(() => {
    if (game?.id) {
      setActiveTab(game?.type);
    }
  }, [game?.id, game?.type]);

  const a11yProps = useCallback(
    (item: SelectionItem, index: number) => {
      const isActivated = activeTab === item.id;
      return {
        id: `create-game-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        sx: {
          textTransform: 'none',
          fontWeight: isActivated ? 'bold' : 'general'
        },
        label: (
          <KLabel.Text
            style={{ fontWeight: isActivated ? 'bold' : 'normal' }}
            color={isActivated ? '#114fbe' : '#8b8c9f'}
          >
            {item.title}
          </KLabel.Text>
        )
      };
    },
    [activeTab]
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const found = AVAILABLE_GAMES.find((_, index) => index === newValue);
      setActiveTab(found?.id || '');
    },
    []
  );

  const availableGames = useMemo(() => {
    if (game?.id) {
      return AVAILABLE_GAMES.filter(i => i.id === game?.type);
    }

    return AVAILABLE_GAMES;
  }, [game?.id, game?.type]);

  const renderGameSettings = useCallback(() => {
    return availableGames.map(i => {
      const props = { isActive: activeTab === i.id, key: `${i.id}` };
      switch (i.id) {
        case GAMES.TABOO:
          return <CreateGameTabooForm {...props} />;
        case GAMES.HOTSEAT:
          return <CreateGameHotseatForm {...props} />;
        case GAMES.JUMBLE:
          return <CreateGameJumbleForm {...props} />;
        case GAMES.CATEGORIES:
          return <CreateGameCategoriesForm {...props} />;
        case GAMES.SNAP:
          return <CreateGameSnapForm {...props} />;
        default:
          return null;
      }
    });
  }, [activeTab, availableGames]);

  const renderTab = useCallback(() => {
    return (
      <>
        <PageHeader title={game?.label ?? trans('common.create_game')} />

        <Tabs
          value={availableGames.findIndex(i => i.id === activeTab)}
          onChange={handleChange}
          aria-label="pronto tabs create-game"
          textColor="primary"
          indicatorColor="secondary"
          TabIndicatorProps={{ hidden: true }}
        >
          {availableGames.map((i, index) => (
            <Tab {...a11yProps(i, index)} key={`tab-item-${index}`} />
          ))}
        </Tabs>

        <KContainer.View
          width={'100%'}
          height={4}
          br={'round'}
          background={KColors.primary.normal}
        />

        {renderGameSettings()}
      </>
    );
  }, [
    a11yProps,
    activeTab,
    availableGames,
    game?.label,
    handleChange,
    renderGameSettings
  ]);

  return renderTab();
};

export default memo(withMPadding(CreateGame));
