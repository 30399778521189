import { KSpacingValue, useDimensionsChanged } from 'uikit';

const useInGameImageSize = () => {
  const { width, height } = useDimensionsChanged();
  let SIZE = (Math.min(width, height) - KSpacingValue['1rem'] * 2) / 1.6;

  SIZE = SIZE > 400 ? 400 : SIZE;

  return SIZE;
};

export default useInGameImageSize;
