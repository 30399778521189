export default function isSSR() {
  return typeof window === 'undefined';
}

export const getIOSVersion = () => {
  if (isSSR()) {
    return undefined;
  }

  const userAgentInfo = window.navigator.userAgent;

  let res: number | undefined;

  // if current device is working on iOS, return the version as number,
  // otherwise return undefined
  if (userAgentInfo.includes('iPhone')) {
    const versionInfo: RegExpMatchArray | null = userAgentInfo.match(
      /iPhone OS ([0-9]+([_][0-9]*)?)/gi // since the userAgent for iPhone shows 'iPhone OS 15_5'
    );

    if (versionInfo !== null) {
      res = parseFloat(versionInfo[0].replace('_', '.').split(' ')[2]);
    }
  }

  return res;
};

export function isMobile(): boolean {
  if (isSSR()) {
    return false;
  }

  const { userAgent } = navigator;

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
}

export const getOS = () => {
  if (isSSR()) {
    return '';
  }

  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // @ts-ignore
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return '';
};

export const iOSVersion = getIOSVersion();

export const IS_IOS_LESS_THAN_13 = iOSVersion ? iOSVersion < 13 : false;
