// import { MUTATION_KEYS } from '@constants';
// import { useMutationState } from '@tanstack/react-query';
import { IAuthInstance } from '@ui';
import { useGoogleVerifyWithCode } from 'hooks';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef
} from 'react';
import {
  KButton,
  KColors,
  KContainer,
  KDims,
  useDimensionsChanged
} from 'uikit';

import Content from './Auth.Content';

const Auth = forwardRef<IAuthInstance, any>((_, ref) => {
  const containerRef = useRef<any>(null);
  const contentRef = useRef<any>(null);

  const isMobile = KDims.isMobile();

  const { width, height } = useDimensionsChanged();

  const { mutate } = useGoogleVerifyWithCode();

  // const status = useMutationState({
  //   filters: { mutationKey: [MUTATION_KEYS.googleVerify] },
  //   select: mutation => mutation.state.status
  // });

  // const isGoogleLoading = !!status?.[0] && status?.[0] === 'pending';

  const dismiss = useCallback(() => {
    document.body.style.overflow = 'auto';
    if (containerRef.current) {
      containerRef.current.style.opacity = 0;
      containerRef.current.style.height = 0;
      containerRef.current.style.zIndex = 0;
    }
  }, []);

  const open = useCallback(
    (initialIndex = 0, initialIsForgot = false) => {
      contentRef.current?.setTabIndex(initialIndex);
      contentRef.current?.setIsForgot(initialIsForgot);
      document.body.style.overflow = 'hidden';
      if (containerRef.current) {
        containerRef.current.style.opacity = 1;
        containerRef.current.style.height = `${height}px`;
        containerRef.current.style.zIndex = 1200;
      }
    },
    [height]
  );

  useImperativeHandle(
    ref,
    () => ({
      setTabIndex: contentRef.current?.setTabIndex,
      openWithCode: (code: string) => {
        open(0, false);
        if (code) {
          mutate(code);
        }
      },
      open,
      dismiss
    }),
    [dismiss, mutate, open]
  );

  if (isMobile || KDims.getWindowSize().width < 1000) {
    return (
      <KContainer.View
        ref={containerRef}
        position="fixed"
        background={KColors.white}
        className="left-0 right-0 bottom-0 transition-[opacity,transform,height,z-index] duration-500"
        style={{ ...styles.container, height: 0, overflowY: 'auto' }}
      >
        <KContainer.View
          style={{
            backgroundImage: 'url("/images/bg_auth.webp")',
            backgroundSize: 'contain'
          }}
          paddingT={((width * 170) / 375) as any}
        >
          <KContainer.View
            dp="flex"
            brTL="7x"
            brTR="7x"
            padding="1.5rem"
            background={KColors.white}
            position="relative"
          >
            <KContainer.View
              position="absolute"
              className="-top-[46px] right-[24px]"
            >
              <KButton.Icon
                icon="Close"
                background={KColors.secondary.normal}
                onPress={dismiss}
              />
            </KContainer.View>

            <Content ref={contentRef} />
          </KContainer.View>
        </KContainer.View>
      </KContainer.View>
    );
  }

  return (
    <KContainer.View
      ref={containerRef}
      position="fixed"
      background={KColors.white}
      className="left-0 right-0 bottom-0 transition-[opacity,transform,height,z-index] duration-500"
      style={{ ...styles.container, height: 0, overflowY: 'auto' }}
    >
      <KContainer.View height="100vh" row center>
        <KContainer.Card row center width="fit-content" padding="0rem">
          <KContainer.View
            style={{
              backgroundImage: 'url("/images/bg_auth_v.webp")',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              zIndex: 100
            }}
            height={700}
            width={(496 * 700) / 693}
          ></KContainer.View>

          <KContainer.View paddingH="1.75rem" height="100%" width={519}>
            <KContainer.View
              position="absolute"
              className="-top-[46px] right-0"
            >
              <KButton.Icon
                icon="Close"
                background={KColors.secondary.normal}
                onPress={dismiss}
              />
            </KContainer.View>

            <Content ref={contentRef} />
          </KContainer.View>
        </KContainer.Card>
      </KContainer.View>
    </KContainer.View>
  );
});

export default memo(Auth);

const styles = {
  container: {
    opacity: 0,
    zIndex: 0
  }
};
