import { IS_TEACHER } from '@constants';
import { IWord } from '@dto';
import { useGameContext } from 'context/GameContext';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KButton, KContainer, KInput } from 'uikit';

const ReviewingWords = () => {
  const { gameInfo, toggleShowReviewingWords } = useGameContext();

  const renderWord = useCallback((i: IWord, idx: number) => {
    return (
      <KContainer.View key={i.id} marginB="1.5rem">
        <KInput.TextField
          name={`word.${i.id}-${i.texts?.[0] ?? ''}`}
          label={`${trans('common.word')} ${idx + 1}`}
          value={i.texts?.[0] ?? ''}
          readOnly
        />

        <KContainer.View row alignItems marginT="0.75rem">
          <KInput.Autocomplete
            name={`bannedWord.${i.id}-${i.texts?.[0] ?? ''}`}
            clearIcon={false}
            freeSolo
            multiple
            options={[]}
            placeholder={trans('placeholder.banned_words')}
            value={i.banneds}
            readOnly
          />
        </KContainer.View>
      </KContainer.View>
    );
  }, []);

  return (
    <>
      {(gameInfo?.words ?? []).map((i, idx) => renderWord(i, idx))}

      {IS_TEACHER && (
        <KButton.Solid
          width={'100%'}
          title={trans('common.score_discussion')}
          onPress={toggleShowReviewingWords}
        />
      )}
    </>
  );
};

ReviewingWords.displayName = 'Containers.InGame.Reviewing.Words';

export default memo(ReviewingWords);
