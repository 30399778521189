import React, { memo } from 'react';
import { KContainer, KDims, KImage, KViewProps } from 'uikit';

interface IProps extends KViewProps {
  uri: string;
}

const WIDTH = '100%';
const MIN_HEIGHT = 278;

const InGameImage = ({ uri, children, ...rest }: IProps) => {
  const isMobile = KDims.isMobile();

  return (
    <KContainer.View
      width={WIDTH}
      minH={MIN_HEIGHT}
      br="4x"
      paddingH={isMobile ? '1.5rem' : '2rem'}
      paddingV={isMobile ? '1.5rem' : '2rem'}
      justifyContent={!children ? 'center' : 'space-between'}
      alignItems
      {...rest}
    >
      <KImage.Base source={`/images/inGame/${uri}`} />

      {children}
    </KContainer.View>
  );
};

InGameImage.displayName = 'Containers.InGame.Image';

export default memo(InGameImage);
