import { IconArrowUp } from '@assets/icons/components/IconArrowUp';
import { IconCheckRound } from '@assets/icons/components/IconCheckRound';
import { IconPencil } from '@assets/icons/components/IconPencil';
import { IconFolder } from '@assets/icons/customComponents/IconFolder';
import { IFolderDto, IGameDto } from '@dto';
import loadable from '@loadable/component';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import { makeStyles } from '@mui/styles';
import GameItem from 'containers/GameItem';
import { useBoolean } from 'hooks/core/useBoolean';
import React, { memo, useCallback } from 'react';
import { TransitionGroup } from 'react-transition-group';
import trans from 'translation';
import { KColors, KContainer, KLabel, generateElementId } from 'uikit';
import { UIUtils } from 'utils';

const NewFolder = loadable(() => import('../NewFolder'));

interface IProps {
  id?: string;
  item: IFolderDto;
  games: IGameDto[];
  isChecked?: boolean;
  isView?: boolean;
  setChecked?: (id: number) => void;
  setGameChecked?: (ids: number[]) => void;
  checkedGameIds?: number[];
}

const useStyles = makeStyles({
  content: {
    margin: '0px !important'
  }
});

const EL_TAG = 'folder-item';

const FolderItem = ({
  id,
  item,
  games,
  isChecked: isFolderChecked,
  isView = true,
  setChecked: setFolderChecked,
  setGameChecked,
  checkedGameIds = []
}: IProps) => {
  const { value, toggle } = useBoolean(false);

  const classes = useStyles();

  const onEditFolder = useCallback(
    (e: any) => {
      e.stopPropagation();
      UIUtils.popup.open({
        title: trans('common.name_this_folder'),
        content: () => <NewFolder item={item} />
      });
    },
    [item]
  );

  const hasNoGame = games.length === 0;

  const renderGames = useCallback(() => {
    if (hasNoGame) {
      return null;
    }

    return (
      <TransitionGroup>
        {games.map((i, idx) => {
          const isGameChecked = checkedGameIds.includes(i.id);
          return (
            <Zoom key={`game_${i.id}`}>
              <div>
                <KContainer.View
                  row
                  alignItems
                  paddingH={item.id ? '1rem' : undefined}
                >
                  {!isView && (
                    <KContainer.Touchable
                      onPress={() => {
                        if (isGameChecked) {
                          setGameChecked?.(
                            checkedGameIds.filter(x => x !== i.id)
                          );
                          if (item.id && isFolderChecked) {
                            setFolderChecked?.(item.id);
                          }
                        } else {
                          const newCheckedGameIds = [...checkedGameIds, i.id];
                          setGameChecked?.(newCheckedGameIds);
                          // if (item.id && !isFolderChecked) {
                          //   if (
                          //     games.every(g => newCheckedGameIds.includes(g.id))
                          //   ) {
                          //     setFolderChecked?.(item.id);
                          //   }
                          // }
                        }
                      }}
                      marginR="0.75rem"
                      avoidParentPress
                      paddingB={idx === 0 && !item.id ? '0.75rem' : undefined}
                    >
                      <IconCheckRound
                        fill={
                          isGameChecked ? KColors.primary.normal : '#e0f2ff'
                        }
                        stroke={
                          isGameChecked ? KColors.white : KColors.transparent
                        }
                      />
                    </KContainer.Touchable>
                  )}

                  <KContainer.View flex>
                    <GameItem
                      item={i}
                      paddingT={idx === 0 && !item.id ? 0 : undefined}
                    />
                  </KContainer.View>
                </KContainer.View>

                <Divider color="#e2e8f0" />

                {idx === games.length - 1 && <KContainer.View height={16} />}
              </div>
            </Zoom>
          );
        })}
      </TransitionGroup>
    );
  }, [
    checkedGameIds,
    games,
    hasNoGame,
    isFolderChecked,
    isView,
    item.id,
    setFolderChecked,
    setGameChecked
  ]);

  const renderFolder = useCallback(() => {
    return (
      <KContainer.View
        padding="1rem"
        background="#f6f6f7"
        br="2x"
        row
        alignItems
        width={'100%'}
      >
        {!isView && (
          <KContainer.Touchable
            onPress={() => {
              if (!isFolderChecked) {
                setGameChecked?.([...checkedGameIds, ...games.map(g => g.id)]);
              }
              setFolderChecked?.(item.id);
            }}
            marginR="0.75rem"
            avoidParentPress
          >
            <IconCheckRound
              fill={isFolderChecked ? KColors.primary.normal : '#e0f2ff'}
              stroke={isFolderChecked ? KColors.white : KColors.transparent}
            />
          </KContainer.Touchable>
        )}

        <IconFolder color={item.color} width={36} height={28} />

        <KContainer.View flex marginH="1rem">
          <KLabel.Text numberOfLines={2} typo="TextXMdMedium">
            {item.label}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View row alignItems gap="1rem">
          <KContainer.Touchable
            onPress={onEditFolder}
            size={24}
            row
            center
            br="round"
          >
            <IconPencil stroke={KColors.primary.normal} />
          </KContainer.Touchable>

          {hasNoGame ? null : (
            <IconButton
              sx={{
                padding: 0,
                transform: `rotate(${value ? 0 : 180}deg)`,
                transition: 'all 0.3s'
              }}
            >
              <IconArrowUp
                fill={KColors.black}
                className={'w-[24px] h-[24px] transition-transform'}
              />
            </IconButton>
          )}
        </KContainer.View>
      </KContainer.View>
    );
  }, [
    checkedGameIds,
    games,
    hasNoGame,
    isFolderChecked,
    isView,
    item.color,
    item.id,
    item.label,
    onEditFolder,
    setFolderChecked,
    setGameChecked,
    value
  ]);

  if (!item.id) {
    return <>{renderGames()}</>;
  }

  if (hasNoGame) {
    return <>{renderFolder()}</>;
  }

  return (
    <Accordion
      sx={{ border: 0, padding: 0 }}
      elevation={0}
      onChange={toggle}
      id={generateElementId(id, EL_TAG)}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          margin: 0
        }}
        classes={{
          content: classes.content
        }}
      >
        {renderFolder()}
      </AccordionSummary>

      <AccordionDetails sx={{ border: 0, padding: 0 }}>
        {renderGames()}
      </AccordionDetails>
    </Accordion>
  );
};

(FolderItem as React.ComponentType<any>).displayName = EL_TAG;

export default memo(FolderItem);
