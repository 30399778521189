import { WithChildren } from '@ui';
import cx from 'classnames';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  hasHeight?: boolean;
}

export const DrawerHeader = (props: WithChildren<Props>) => {
  const { className, children, hasHeight = true, ...rest } = props;

  return (
    <>
      <header
        {...rest}
        className={cx(
          'absolute inset-x-0 top-0 z-20 flex h-[64px] items-center bg-white px-12 shadow-300',
          className
        )}
      >
        {children}
      </header>
      <div
        className={cx({
          'h-[64px]': hasHeight
        })}
      />
    </>
  );
};
