import { IconGame } from '@assets/icons/components/IconGame';
import { IconLogout } from '@assets/icons/components/IconLogout';
import { IconUserCircle } from '@assets/icons/components/IconUserCircle';
import { IconX } from '@assets/icons/components/IconX';
import Drawer from '@mui/material/Drawer';
import { useBoolean } from 'hooks/core/useBoolean';
import { usePopup } from 'hooks/usePopup';
import compact from 'lodash/compact';
import { useDashboardGameData } from 'pages/Main/Dashboard/helpers';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import trans from 'translation';
import { KColors, KContainer, KLabel } from 'uikit';

import { ISidebarInstance, ISidebarItem } from './helpers';

interface IProps {
  isDrawer?: boolean;
}

const Sidebar = forwardRef<ISidebarInstance, IProps>(
  ({ isDrawer = true }, ref) => {
    const navigate = useNavigate();

    const { value: isOpen, setTrue, setFalse } = useBoolean(false);

    useImperativeHandle(ref, () => ({
      onOpen: setTrue
    }));

    const { pathname } = useLocation();

    const { gameData } = useDashboardGameData();

    const count = gameData.length;

    const { onLogoutPopup } = usePopup();

    const data: ISidebarItem[] = useMemo(
      () =>
        compact([
          {
            key: '/',
            Icon: IconGame,
            title: 'common.my_games',
            count,
            onPress: () => navigate('/'),
            activeKeys: ['/game', '/room']
          },
          {
            key: '/account',
            Icon: IconUserCircle,
            title: 'common.account',
            onPress: () => navigate('/account')
          },
          !isDrawer
            ? {
                key: 'logout',
                Icon: IconLogout,
                title: 'common.sign_out',
                onPress: () => onLogoutPopup()
              }
            : undefined
        ]),
      [count, isDrawer, navigate, onLogoutPopup]
    );

    const renderItem = useCallback(
      (item: ISidebarItem) => {
        const { key, Icon, title, count: _count, onPress, activeKeys } = item;
        const isActive =
          key === pathname || activeKeys?.some(i => pathname.includes(i));

        return (
          <KContainer.Touchable
            key={key}
            row
            alignItems
            padding="0.75rem"
            gap="0.5rem"
            br="2x"
            background={isActive ? KColors.primary.normal : undefined}
            onPress={() => {
              setFalse();
              onPress();
            }}
          >
            {Icon && (
              <Icon
                width={24}
                height={24}
                fill={KColors.white}
                stroke={
                  key === 'logout'
                    ? '#f77777'
                    : isActive
                    ? KColors.secondary.normal
                    : KColors.primary.normal
                }
              />
            )}

            <KLabel.Text
              typo="TextXNmNormal"
              flex
              color={
                key === 'logout'
                  ? '#f77777'
                  : isActive
                  ? KColors.white
                  : undefined
              }
              textAlign="left"
            >
              {trans(title)}
            </KLabel.Text>

            {_count && (
              <KContainer.View
                background={KColors.secondary.normal}
                size={20}
                br="x"
                center
              >
                <KLabel.Text typo="Text2XsNormal">{_count}</KLabel.Text>
              </KContainer.View>
            )}
          </KContainer.Touchable>
        );
      },
      [setFalse, pathname]
    );

    const content = useMemo(() => {
      return (
        <KContainer.View width="100%" height="100%" padding="1.5rem">
          <KContainer.View gap="0.5rem" dp="flex">
            {data.map(i => renderItem(i))}
          </KContainer.View>
        </KContainer.View>
      );
    }, [data, renderItem]);

    if (!isDrawer) {
      return (
        <KContainer.View width={288} background="#f8fafd" marginL="2rem">
          <KLabel.Paragraph padding="1.5rem" paddingB={0} typo="TextXLgNormal">
            {trans('common.menu')}
          </KLabel.Paragraph>

          {content}
        </KContainer.View>
      );
    }

    return (
      <>
        {isOpen && (
          <KContainer.View
            width={65}
            height={72}
            position="absolute"
            className="top-0 right-[310px] z-[1201]"
            center
          >
            <KContainer.Touchable onPress={() => setFalse()}>
              <IconX fill="black" width={24} height={24} />
            </KContainer.Touchable>
          </KContainer.View>
        )}

        <Drawer
          anchor="right"
          open={isOpen}
          // onClose={onClose}
          PaperProps={{
            sx: {
              width: 310,
              backgroundColor: KColors.transparent
            }
          }}
          sx={{
            width: 310,
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(252,252,252,0.5)',
              backdropFilter: 'blur(5px)'
            }
          }}
        >
          <KContainer.View background={KColors.white}>
            {content}
          </KContainer.View>
        </Drawer>
      </>
    );
  }
);

export default memo(Sidebar);
