import React, { memo } from 'react';

import StudentWaitingForReady from './StudentWaitingForReady';

const WaitingForReady = () => {
  return <StudentWaitingForReady />;
};

WaitingForReady.displayName = 'Containers.InGame.WaitingForReady';

export default memo(WaitingForReady);
