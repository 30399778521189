import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import clone from 'lodash/clone';
import React, { memo, useCallback } from 'react';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KLabel from '../Label';
import { KRadioProps } from '../types';

const KRadio = (props: KRadioProps) => {
  const {
    activeColor,
    typo,
    checked,
    label,
    disabled,
    onChange,
    onAfterChange,
    ...rest
  } = props;

  const toggle = useCallback(() => {
    const currentChecked = clone(checked);
    onChange?.(!currentChecked);
    onAfterChange?.(!currentChecked);
  }, [checked, onChange, onAfterChange]);

  const renderCheckbox = useCallback(() => {
    if (checked) {
      return (
        <RadioButtonChecked
          sx={{ width: 18, color: activeColor, opacity: disabled ? 0.3 : 1 }}
        />
      );
    } else {
      return (
        <RadioButtonUnchecked sx={{ width: 18, opacity: disabled ? 0.3 : 1 }} />
      );
    }
  }, [activeColor, checked, disabled]);

  const Wrapper = disabled ? KContainer.View : KContainer.Touchable;

  return (
    <Wrapper
      onPress={toggle}
      row
      alignItems
      avoidParentPress
      width="fit-content"
      {...rest}
    >
      {renderCheckbox()}

      {label && (
        <KLabel.Text typo={typo} marginL="0.5rem">
          {label}
        </KLabel.Text>
      )}
    </Wrapper>
  );
};

KRadio.defaultProps = {
  activeColor: KColors.primary.normal,
  typo: 'TextNmNormal'
};

export default memo(KRadio);
