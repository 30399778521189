import { IconX } from '@assets/icons/components/IconX';
import { EventName } from '@constants';
import Drawer from '@mui/material/Drawer';
import AppEvent from 'core/event';
import { useUser } from 'hooks';
import { useBoolean } from 'hooks/core/useBoolean';
import compact from 'lodash/compact';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import trans from 'translation';
import { KColors, KContainer, KImage, KLabel } from 'uikit';

import { ISidebarInstance, ISidebarItem } from './helpers';

const FeatureSidebar = forwardRef<ISidebarInstance, any>((_, ref) => {
  const { user } = useUser();

  const { value: isOpen, setTrue, setFalse } = useBoolean(false);

  useImperativeHandle(ref, () => ({
    onOpen: setTrue
  }));

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const data: ISidebarItem[] = useMemo(
    () =>
      compact([
        {
          key: '/',
          title: 'common.home',
          onPress: () => navigate('/')
        },
        {
          key: '/features',
          title: 'common.features',
          onPress: () => navigate('/features')
        },
        {
          key: '/about-us',
          title: 'common.about_us',
          onPress: () => navigate('/about-us')
        },
        {
          key: '/learn-more',
          title: 'common.learn_more',
          onPress: () => navigate('/learn-more')
        },
        !!user
          ? {
              key: 'logout',
              title: 'common.sign_out',
              onPress: () => AppEvent.dispatch(EventName.LOGOUT)
            }
          : undefined
      ]),
    [navigate, user]
  );

  const renderItem = useCallback(
    (item: ISidebarItem) => {
      const { key, title, onPress } = item;
      const isActive = key === pathname;

      return (
        <KContainer.Touchable
          key={key}
          row
          alignItems
          padding="0.75rem"
          gap="0.5rem"
          br="2x"
          background={isActive ? KColors.primary.normal : undefined}
          onPress={() => {
            setFalse();
            onPress();
          }}
        >
          <KLabel.Text
            typo="TextXNmNormal"
            flex
            color={
              key === 'logout'
                ? '#f77777'
                : isActive
                ? KColors.white
                : undefined
            }
            textAlign="left"
          >
            {trans(title)}
          </KLabel.Text>
        </KContainer.Touchable>
      );
    },
    [setFalse, pathname]
  );

  return (
    <>
      {isOpen && (
        <KContainer.View
          width={65}
          height={72}
          position="absolute"
          className="top-0 left-[310px] z-[1201]"
          center
        >
          <KContainer.Touchable onPress={() => setFalse()}>
            <IconX fill="black" width={24} height={24} />
          </KContainer.Touchable>
        </KContainer.View>
      )}

      <Drawer
        anchor="left"
        open={isOpen}
        // onClose={onClose}
        PaperProps={{
          sx: {
            width: 310,
            backgroundColor: KColors.transparent
          }
        }}
        sx={{
          width: 310,
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(252,252,252,0.5)',
            backdropFilter: 'blur(5px)'
          }
        }}
      >
        <KContainer.View
          width="100%"
          height="100%"
          padding="1.5rem"
          background={KColors.white}
        >
          {!!user && (
            <KContainer.View row alignItems gap="0.75rem" marginB="2rem">
              <KImage.Avatar size="lg" />

              <KContainer.View>
                <KLabel.Paragraph typo="TextMdNormal">
                  {user?.username}
                </KLabel.Paragraph>

                <KLabel.Text
                  marginT="0.25rem"
                  typo="TextSmNormal"
                  color={KColors.primary.normal}
                >
                  {trans('common.teacher')}
                </KLabel.Text>
              </KContainer.View>
            </KContainer.View>
          )}

          <KContainer.View gap="0.5rem" dp="flex">
            {data.map(i => renderItem(i))}
          </KContainer.View>
        </KContainer.View>
      </Drawer>
    </>
  );
});

export default memo(FeatureSidebar);
