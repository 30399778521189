import React, { memo } from 'react';
import { Trans } from 'react-i18next';

import KText from './Text';

import { KRichTextProps } from '../types';

const KRichText = (props: KRichTextProps) => {
  const { richTextOptions, ...rest } = props;

  return (
    <KText {...rest}>
      <Trans {...richTextOptions} {...rest} />
    </KText>
  );
};

export default memo(KRichText);
