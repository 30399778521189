import InputAdornment from '@mui/material/InputAdornment';
import React, { forwardRef, memo, useRef } from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';

import { IconCalendar } from '../../assets/icons/components/IconCalendar';
import { KColors } from '../../constants';
import KInput from '../Input';

type IProps = DatePickerProps & {
  label?: string;
  message?: string;
  required?: boolean;
};

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <KInput.TextField
      ref={ref}
      placeholder="Jan 01/2000"
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconCalendar fill={KColors.transparent} stroke={KColors.black} />
          </InputAdornment>
        )
      }}
    />
  );
});

const KDatePicker = forwardRef<DatePicker, IProps>((props, ref) => {
  const { selected, maxDate, ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <DatePicker
      ref={ref}
      dateFormat="MMM dd/yyyy"
      wrapperClassName="dp-full-width"
      className="dp-full-width"
      {...rest}
      maxDate={maxDate}
      selected={selected}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      customInput={<CustomInput ref={inputRef} {...rest} />}
    />
  );
});

export default memo(KDatePicker);
