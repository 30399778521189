import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { useCombineRefs } from '../../hooks';
import KButton from '../Button';
import { KInputProps } from '../types';
import { TypoHelper, TypoType, useTheme } from '../Typography';

const useInputOptions = (
  props: KInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const {
    style,
    // type,
    inputType,
    autoCapitalize = 'none',
    size,
    readOnly,
    inputProps,
    InputProps,
    InputLabelProps,
    SelectProps,
    options,
    message,
    hint,
    onFocus,
    onBlur,
    select,
    error,
    helperText,
    multiple,
    ...rest
  } = props;

  const innerRef = useRef<HTMLInputElement>();
  const combinedRef = useCombineRefs<HTMLInputElement>(ref, innerRef);

  const [hasFocus, setHasFocus] = useState(props.autoFocus);
  const [hidden, setHidden] = useState(inputType === 'password');

  const typos = useTheme();

  const typo: TypoType = useMemo(() => {
    switch (size) {
      case 'small':
        return 'TextSmNormal';
      case 'x-small':
        return 'TextXsNormal';
      default:
        return 'TextNmNormal';
    }
  }, [size]);

  const { innerStyle, innerProps } = useMemo(() => {
    const { mStyle: s, mProps: p } = TypoHelper.destructPropsToStyle(rest);
    const typoStyle = typo ? typos[typo] : undefined;
    const mergeStyles = {
      ...typoStyle,
      ...style,
      // ...(!hasFocus ? styles.notFocus : undefined),
      ...s.layout,
      ...s.spacing,
      ...s.styling,
      ...s.text
    };
    return {
      innerStyle: mergeStyles,
      innerProps: p
    };
  }, [rest, typo, typos, style]);

  const _onFocus = useCallback(
    (e: any) => {
      setHasFocus(true);
      onFocus?.(e);
    },
    [onFocus]
  );

  const _onBlur = useCallback(
    (e: any) => {
      setHasFocus(false);
      onBlur?.(e);
    },
    [onBlur]
  );

  const _onToggleEye = useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  const renderEye = useMemo(() => {
    return (
      <KButton.Icon
        icon={!hidden ? 'Visibility' : 'VisibilityOff'}
        edge="end"
        onPress={_onToggleEye}
      />
    );
  }, [_onToggleEye, hidden]);

  const _SelectProps = useMemo(() => {
    const mSelectProps = {
      ...SelectProps,
      multiple,
      displayEmpty: true
    };
    return mSelectProps;
  }, [SelectProps, multiple]);

  const renderOptions = useMemo(() => {
    if (options) {
      return options.map(o => (
        <MenuItem key={o.id} value={o.id} disabled={o.disabled}>
          {o.title}
        </MenuItem>
      ));
    }

    return null;
  }, [options]);

  const _inputProps = useMemo(() => {
    const mInputProps = {
      ...inputProps,
      autoCapitalize,
      style: !hasFocus ? styles.notFocus : undefined
    };
    if (renderOptions && rest?.disabled) {
      // @ts-ignore
      mInputProps.IconComponent = () => null;
    }

    return mInputProps;
  }, [autoCapitalize, inputProps, renderOptions, rest?.disabled, hasFocus]);

  const _InputProps = useMemo(() => {
    const mInputProps = {
      ...InputProps,
      readOnly
    };
    if (inputType === 'password') {
      mInputProps.endAdornment = (
        <InputAdornment position="end">{renderEye}</InputAdornment>
      );
    }
    return mInputProps;
  }, [InputProps, inputType, readOnly, renderEye]);

  const _InputLabelProps = useMemo(() => {
    return {
      ...InputLabelProps,
      shrink: true
    };
  }, [InputLabelProps]);

  return {
    combinedRef,
    hasFocus,
    innerStyle,
    innerProps,
    message,
    hint,
    error: error || !!message,
    helperText: helperText || message || hint,
    hidden,
    renderOptions,
    inputProps: _inputProps,
    InputProps: _InputProps,
    InputLabelProps: _InputLabelProps,
    SelectProps: _SelectProps,
    onBlur: _onBlur,
    onFocus: _onFocus,
    type: hidden ? 'password' : inputType === 'password' ? 'text' : inputType,
    select: options || select ? true : false
  };
};

const NumberFormat = memo(
  forwardRef<NumericFormatProps, any>((props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        // prefix="$ "
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (
            !(
              e.key &&
              (parseInt(e.key) >= 0 ||
                ['.', 'Backspace'].includes(e.key) ||
                ((e.ctrlKey || e.metaKey) && ['c', 'v', 'x'].includes(e.key)))
            )
          ) {
            e.preventDefault();
          }
        }}
        thousandSeparator
        valueIsNumericString
        allowNegative={false}
      />
    );
  })
);

export { useInputOptions, NumberFormat };

const styles = {
  notFocus: {
    background: '#f6f6f7'
  }
};
