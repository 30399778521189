import React, { memo, useMemo } from 'react';
import { KColors, KContainer, KViewProps } from 'uikit';

interface IProps extends Omit<KViewProps, 'background'> {
  kind?: 'primary' | 'secondary';
}

const InGamePackageView = (props: IProps) => {
  const { kind = 'primary', children, ...rest } = props;

  const options = useMemo(() => {
    return {
      background: kind === 'primary' ? KColors.primary.normal : '#F0F5FF',
      innerBackground:
        kind === 'primary' ? KColors.highlight.normal : KColors.primary.normal
    };
  }, [kind]);

  return (
    <KContainer.View
      br="2x"
      marginT="1rem"
      position="relative"
      background={options.background}
      width={'100%'}
      padding="1.5rem"
      {...rest}
    >
      <KContainer.View
        position="absolute"
        br="round"
        size={48}
        background={options.background}
        center
        style={{
          top: -13,
          left: 'calc(100%/2 - 24px)'
        }}
      />

      <KContainer.View
        position="absolute"
        br="round"
        size={8}
        background={options.innerBackground}
        style={{
          top: -5,
          left: 'calc(100%/2 - 4px)'
        }}
      />

      {children}
    </KContainer.View>
  );
};

InGamePackageView.displayName = 'Containers.InGame.PackageView';

export default memo(InGamePackageView);
