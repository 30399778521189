import { E_RACE_MODES, WORD_STATUSES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import { useTeacherInGameInfo } from 'hooks';
import { useRemainingTimeChanged } from 'hooks/game/useRemainingTimeChanged';
import React, { memo, useMemo } from 'react';
import trans from 'translation';
import { KButton, KContainer, KDims } from 'uikit';

import InGameImage from '../InGame.Image';
import InGameRemainingTime from '../InGame.RemainingTime';
import TimesUp from '../TimesUp';

const Playing = () => {
  const { onRequestPause, isChaoticMode, isWholeClassMode, raceMode } =
    useGameContext();

  const { runningTeam } = useTeacherInGameInfo();

  const runningWord = useMemo(() => {
    return runningTeam?.words?.find(
      i =>
        i.status === WORD_STATUSES.RUNNING || i.status === WORD_STATUSES.TIME_UP
    );
  }, [runningTeam?.words]);

  const time = useRemainingTimeChanged();

  const isMobile = KDims.isMobile();

  const hasRemainingTime = raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id;

  if (runningWord?.status === WORD_STATUSES.TIME_UP && isWholeClassMode) {
    return <TimesUp />;
  }

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri={isMobile ? 'game-on.webp' : 'game-on-2.webp'}
        background="#EFCA2F"
      >
        {!isChaoticMode && (
          <KContainer.View width="100%">
            {hasRemainingTime && (
              <InGameRemainingTime remainingTime={time} marginT="3rem" />
            )}

            {!isMobile && (
              <KButton.Solid
                width={'100%'}
                marginT={hasRemainingTime ? '2rem' : undefined}
                title={trans('common.pause_game')}
                onPress={onRequestPause}
              />
            )}
          </KContainer.View>
        )}
      </InGameImage>

      {isMobile && !isChaoticMode && (
        <KButton.Solid
          marginT="1.5rem"
          width={'100%'}
          title={trans('common.pause_game')}
          onPress={onRequestPause}
        />
      )}
    </KContainer.View>
  );
};

Playing.displayName = 'Containers.InGame.TeacherPlaying';

export default memo(Playing);
