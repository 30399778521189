import { IS_TEACHER } from '@constants';
import {
  E_GAME_MODES,
  GAMES,
  HOTSEAT_CONFIGS,
  JUMBLE_CONFIGS,
  TABOO_CONFIGS
} from '@constants/gameConfigs';
import { IStudentDto } from '@dto';
import { useGameContext } from 'context/GameContext';
import AppAudio from 'core/audio';
import AppSignalR, { SIGNALR_EVENTS } from 'core/signalR';
import AppValidation from 'core/validation';
import { useResolverForm } from 'hooks';
import { usePopup } from 'hooks/usePopup';
import { isEmpty, set } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KDims,
  KInput,
  KLabel,
  useMount
} from 'uikit';
import { UIUtils } from 'utils';
import { v4 as uuidv4 } from 'uuid';

import { IFormData } from './helpers';
import ObservationItem from './ObservationItem';
import TeamItem from './TeamItem';

import InGamePackageView from '../InGame.PackageView';

const WaitingRoom = () => {
  const { roomId = '' } = useParams();

  const { roomInfo } = useGameContext();

  const { gameSetup } = roomInfo || {};

  const gameType = gameSetup?.type ?? GAMES.TABOO;
  const gameMode = gameSetup?.gameMode ?? '';

  const isHotseat = gameType === GAMES.HOTSEAT;
  const isWholeClass = gameMode === E_GAME_MODES.WHOLE_CLASS.id;

  const gameConfigs = useMemo(() => {
    switch (gameType) {
      case GAMES.TABOO:
        return {
          ...TABOO_CONFIGS,
          AVAILABLE_TEAMS: isWholeClass
            ? [TABOO_CONFIGS.AVAILABLE_TEAMS[0]]
            : TABOO_CONFIGS.AVAILABLE_TEAMS
        };

      case GAMES.HOTSEAT:
        return HOTSEAT_CONFIGS;

      case GAMES.JUMBLE:
        return JUMBLE_CONFIGS;

      default:
        return TABOO_CONFIGS;
    }
  }, [gameType, isWholeClass]);

  const initValues = useMemo(() => {
    return {
      numberOfTeams:
        gameSetup?.numberOfTeams ?? gameConfigs.AVAILABLE_TEAMS[0].id,
      numberOfStudents:
        gameSetup?.numberOfStudents ?? gameConfigs.NUMBER_OF_STUDENTS[0].id,
      observations: roomInfo?.observations ?? [],
      teams: roomInfo?.teams ?? [],
      users: roomInfo?.users ?? []
    };
  }, [
    gameConfigs.AVAILABLE_TEAMS,
    gameConfigs.NUMBER_OF_STUDENTS,
    gameSetup?.numberOfStudents,
    gameSetup?.numberOfTeams,
    roomInfo?.observations,
    roomInfo?.teams,
    roomInfo?.users
  ]);

  const methods = useResolverForm<IFormData>({
    schema: AppValidation.init(),
    configs: {
      values: initValues
    }
  });

  const [numberOfTeams, teams, users, observations] = useWatch({
    control: methods.control,
    name: ['numberOfTeams', 'teams', 'users', 'observations']
  });

  useMount(() => {
    const sound = '/sounds/waiting_room.mp3';

    if (IS_TEACHER) {
      AppAudio.playSound(sound, { loop: true });
    }

    return () => {
      if (IS_TEACHER) {
        AppAudio.stopSound();
      }
    };
  });

  const onStartGame = useCallback(() => {
    AppAudio.playSound('/sounds/launch_game.mp3', {
      cb: () => AppSignalR.emit(SIGNALR_EVENTS.START_GAME, {})
    });
  }, []);

  const { onRemovePopup } = usePopup();

  const onTerminateRoom = useCallback(() => {
    onRemovePopup({
      title: trans('common.terminate_room'),
      content: trans('common.terminate_room_message'),
      buttons: [
        {
          variant: 'outline',
          title: trans('common.cancel')
        },
        {
          key: 'main',
          variant: 'solid',
          title: trans('common.terminate'),
          onPress: () => AppSignalR.emit(SIGNALR_EVENTS.TERMINATE_ROOM, {}),
          withDismiss: true
        }
      ]
    });
  }, [onRemovePopup]);

  const teamObj = useMemo(
    () =>
      users.reduce<Record<string, number>>((acc, cur) => {
        if (cur.teamId) {
          if (acc[cur.teamId]) {
            acc[cur.teamId] += 1;
          } else {
            acc[cur.teamId] = 1;
          }
        }
        return acc;
      }, {}),
    [users]
  );

  const isDisabled = useMemo(() => {
    if (isEmpty(teamObj)) {
      return true;
    }

    const numberOfStudents = gameSetup?.numberOfStudents || 0;

    if (Object.values(teamObj).some(i => i < 2 || i > numberOfStudents)) {
      return true;
    }

    return false;
  }, [gameSetup?.numberOfStudents, teamObj]);

  const isMobile = KDims.isMobile();

  const numberOfStudentsOptions = useMemo(() => {
    if (isWholeClass) {
      return gameConfigs.NUMBER_OF_STUDENTS.filter(
        i => i.id <= (gameSetup?.words ?? []).length / 2
      );
    } else {
      return gameConfigs.NUMBER_OF_STUDENTS;
    }
  }, [gameConfigs.NUMBER_OF_STUDENTS, gameSetup?.words, isWholeClass]);

  return (
    <FormProvider {...methods}>
      <KContainer.View>
        <InGamePackageView
          marginT="1.75rem"
          marginB="2rem"
          br="2x"
          paddingH="1.5rem"
          paddingV="2rem"
        >
          <KLabel.Paragraph
            textAlign
            typo="TextXLgBold"
            color={KColors.highlight.normal}
            textTransform="uppercase"
          >
            {gameSetup?.type} {trans('common.game')}
          </KLabel.Paragraph>

          <KContainer.View marginT="1.5rem">
            <Controller
              name="numberOfTeams"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.Selection
                    {...field}
                    label={
                      trans('common.maximum_number_of_teams') +
                      `${isHotseat ? ' (Only 2 teams}' : ''}`
                    }
                    options={gameConfigs.AVAILABLE_TEAMS}
                    color={KColors.white}
                    disabled={!IS_TEACHER || isHotseat}
                    onChange={e => {
                      const _numberOfTeams = parseInt(e.target.value, 10);
                      const socketData: any = {
                        ...roomInfo,
                        gameSetup: {
                          ...gameSetup,
                          numberOfTeams: _numberOfTeams
                        }
                      };

                      if (_numberOfTeams < numberOfTeams) {
                        const remainingTeams = teams.slice(0, _numberOfTeams);
                        const remainingTeamIds = remainingTeams.map(t => t.id);

                        const _observations = [
                          ...(roomInfo?.observations ?? [])
                        ];
                        const _users: IStudentDto[] = [];

                        roomInfo?.users?.forEach(u => {
                          if (u.teamId && remainingTeamIds.includes(u.teamId)) {
                            _users.push(u);
                          } else {
                            _observations.push({ ...u, teamId: null });
                          }
                        });

                        set(socketData, 'teams', remainingTeams);
                        set(socketData, 'users', _users);
                        set(socketData, 'observations', _observations);

                        if (
                          _observations.length >
                          (roomInfo?.observations ?? []).length
                        ) {
                          onRemovePopup({
                            title: trans(
                              'common.change_maximum_number_of_teams'
                            ),
                            content: trans(
                              'common.change_maximum_number_of_teams_message'
                            ),
                            buttons: [
                              {
                                variant: 'outline',
                                title: trans('common.cancel')
                              },
                              {
                                key: 'main',
                                variant: 'solid',
                                title: trans('common.submit'),
                                onPress: () =>
                                  AppSignalR.emit(
                                    SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS,
                                    socketData
                                  ),
                                withDismiss: true
                              }
                            ]
                          });
                        } else {
                          AppSignalR.emit(
                            SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS,
                            socketData
                          );
                        }
                      } else if (_numberOfTeams > numberOfTeams) {
                        const _teams = [
                          ...teams,
                          ...Array.from(
                            Array(_numberOfTeams - numberOfTeams),
                            (_, idx) => {
                              return {
                                id: uuidv4(),
                                name: `Team ${teams.length + idx + 1}`,
                                tableId: null,
                                words: null,
                                finalScore: null
                              };
                            }
                          )
                        ];
                        set(socketData, 'teams', _teams);
                        AppSignalR.emit(
                          SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS,
                          socketData
                        );
                      }
                    }}
                  />
                );
              }}
            />
          </KContainer.View>

          <KContainer.View marginT="1.5rem">
            <Controller
              name="numberOfStudents"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.Selection
                    {...field}
                    label={trans('common.maximum_member_of_teams')}
                    options={numberOfStudentsOptions}
                    color={KColors.white}
                    disabled={!IS_TEACHER}
                    onChange={e => {
                      const _numberOfStudents = parseInt(e.target.value, 10);
                      const socketData: any = {
                        ...roomInfo,
                        gameSetup: {
                          ...gameSetup,
                          numberOfStudents: _numberOfStudents
                        }
                      };

                      const max = Math.max(
                        ...(Object.values(teamObj) as number[])
                      );

                      if (max > _numberOfStudents) {
                        UIUtils.snackBar.open({
                          message: `Please update number of students in team less than or equal to ${max}`,
                          status: 'danger'
                        });
                      } else {
                        set(socketData, 'numberOfStudent', _numberOfStudents);
                        field.onChange(e);
                        AppSignalR.emit(
                          SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS,
                          socketData
                        );
                      }
                    }}
                  />
                );
              }}
            />
          </KContainer.View>

          <KContainer.View marginT="1.5rem">
            <KLabel.Paragraph typo="TextNmBold" color={KColors.white}>
              {trans('common.observations')}
            </KLabel.Paragraph>

            {observations.map((i, idx) => {
              return (
                <ObservationItem
                  key={`observation-${i.teamId}-${idx}-${i.id}-${i.username}-${i.status}`}
                  item={i}
                  index={idx}
                />
              );
            })}
          </KContainer.View>

          {teams.map((i, idx) => {
            const teamUsers = users.filter(u => u.teamId === i.id);
            return (
              <TeamItem
                key={`team-${i.id}-${idx}-${JSON.stringify(teamUsers)}`}
                item={i}
              />
            );
          })}

          {/* {IS_TEACHER && teams.length < numberOfTeams && (
            <KButton.Dashed
              title={'+ ' + trans('common.add_more_team')}
              brC={KColors.white}
              textColor={KColors.white}
              width={'100%'}
              marginT="2rem"
              onPress={() => {
                append({
                  id: teams.length + 1,
                  name: '',
                  students: []
                });
              }}
            />
          )} */}
        </InGamePackageView>

        {IS_TEACHER && (
          <KContainer.View
            marginH={isMobile ? '1.25rem' : undefined}
            width={isMobile ? 'calc(100% - 3rem)' : '100%'}
          >
            <KButton.Solid
              title={trans('common.game_start')}
              onPress={onStartGame}
              width="100%"
              disabled={isDisabled}
            />

            <KButton.Solid
              title={trans('common.terminate_room')}
              onPress={onTerminateRoom}
              width="100%"
              marginT="1.25rem"
            />
          </KContainer.View>
        )}

        <KContainer.View
          row
          alignItems
          gap="1rem"
          marginV="1.75rem"
          marginH={isMobile ? '1.25rem' : undefined}
        >
          <KContainer.View
            br="5x"
            brW={1}
            brC={KColors.primary.normal}
            padding="1rem"
            dp="flex"
            alignItems
            justifyContent="space-between"
            height={150}
            flex
          >
            <KLabel.Paragraph typo="TextXNmBold" color={KColors.primary.normal}>
              {process.env.REACT_APP_STUDENT_URL?.split('//')[1]}
            </KLabel.Paragraph>

            <KLabel.Paragraph typo="H1" color={KColors.primary.normal}>
              {roomId}
            </KLabel.Paragraph>

            <KLabel.Paragraph
              typo="TextNmBold"
              textTransform="uppercase"
              color={KColors.primary.normal}
            >
              {trans('common.game_code')}
            </KLabel.Paragraph>
          </KContainer.View>

          <KContainer.View
            br="5x"
            brW={1}
            brC={KColors.primary.normal}
            padding="1rem"
            dp="flex"
            alignItems
            height={150}
            flex
          >
            <KContainer.View dp="flex" flex alignItems>
              <QRCode
                size={86}
                value={`${process.env.REACT_APP_STUDENT_URL}?code=${roomId}`}
              />
            </KContainer.View>

            <KLabel.Text
              typo="TextNmBold"
              textTransform="uppercase"
              color={KColors.primary.normal}
            >
              {trans('common.game_qr_code')}
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>
      </KContainer.View>
    </FormProvider>
  );
};

WaitingRoom.displayName = 'Containers.InGame.WaitingRoom';

export default memo(WaitingRoom);
