import { STUDENT_STATUSES, TABLE_STATUSES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import { useStudentInGameInfo } from 'hooks';
import useInGameImageSize from 'hooks/room/useInGameImageSize';
import React, { memo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KImage, KLabel } from 'uikit';

import WaitingForStart from '../WaitingForStart';

const StudentWaitingForReady = () => {
  const SIZE = useInGameImageSize();

  const { onStudentReady, isChaoticMode } = useGameContext();

  const { myProfile, myTable, myTeam } = useStudentInGameInfo();

  const status = myTable?.status;

  if (!isChaoticMode || (isChaoticMode && myTeam?.isPlaying)) {
    return (
      <KContainer.View alignItems padding="1.5rem">
        <div
          style={{
            backgroundImage: 'url("/images/inGame/waiting-for-ready.webp")',
            backgroundSize: 'contain'
          }}
        >
          <KContainer.View
            width={SIZE * 1.5}
            height={(SIZE * 1.5) / 1.18}
            br={'4x'}
            alignItems
            justifyContent
          >
            <KContainer.View flex justifyContent="flex-end">
              <KImage.Avatar source="/images/dashboard_2.webp" size="xlg" />
            </KContainer.View>

            <KLabel.Paragraph
              typo="Text3xLgMedium"
              color={KColors.palette.warning.w200}
              marginT="0.25rem"
            >
              {myProfile.username}
            </KLabel.Paragraph>

            <KContainer.View flex>
              {myTeam?.isPlaying &&
              status === TABLE_STATUSES.WAITING_FOR_READY ? (
                <KButton.Solid
                  tintColor={KColors.primary.normal}
                  title={trans('common.ready')}
                  kind="gray"
                  enhanceStyle={{
                    paddingInline: 50,
                    top: 40
                  }}
                  background={KColors.white}
                  textColor={KColors.primary.normal}
                  weight="medium"
                  onPress={onStudentReady}
                  disabled={myProfile.status === STUDENT_STATUSES.READY}
                />
              ) : null}
            </KContainer.View>
          </KContainer.View>
        </div>
      </KContainer.View>
    );
  }

  return <WaitingForStart status={status} />;
};

StudentWaitingForReady.displayName = 'Containers.InGame.StudentWaitingForReady';

export default memo(StudentWaitingForReady);
