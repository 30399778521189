import Grow, { GrowProps } from '@mui/material/Grow';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { WithSnackBarProps } from '@ui';
import React, {
  forwardRef,
  memo,
  useImperativeHandle,
  useState,
  useCallback
} from 'react';
import { KColors, KContainer, KLabel, KSnackBarProps } from 'uikit';

interface State extends SnackbarOrigin {
  open: boolean;
}

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

const KSnackBar = forwardRef<WithSnackBarProps>((_, ref) => {
  const [snackBarProps, setSnackBarProps] = useState<KSnackBarProps>({
    message: ''
  });

  const {
    message,
    autoHideDuration = 5000,
    status = 'info'
  } = snackBarProps || {};

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;

  const handleClose = useCallback(() => {
    setState(prev => ({ ...prev, open: false }));
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: (payload: KSnackBarProps) => {
        setSnackBarProps(payload);
        setState({ ...state, open: true });
      },
      dismiss: () => setState(prev => ({ ...prev, open: false }))
    }),
    [state]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
      TransitionComponent={GrowTransition}
      autoHideDuration={autoHideDuration}
      ContentProps={{
        style: {
          backgroundColor:
            status === 'danger'
              ? KColors.danger.normal
              : status === 'warning'
              ? KColors.warning.normal
              : status === 'success'
              ? KColors.success.normal
              : KColors.highlight.normal
        }
      }}
      ClickAwayListenerProps={{ onClickAway: handleClose }}
    >
      <div>
        <KContainer.View
          background={
            status === 'danger'
              ? KColors.danger.normal
              : status === 'warning'
              ? KColors.warning.normal
              : status === 'success'
              ? '#01dfb7'
              : KColors.highlight.normal
          }
          paddingH={'1rem'}
          paddingV={'0.75rem'}
          br={'x'}
        >
          {typeof message === 'string' ? (
            <KLabel.Text
              typo="TextLgMedium"
              color={status === 'info' ? KColors.gray.dark : KColors.white}
            >
              {message}
            </KLabel.Text>
          ) : (
            message
          )}
        </KContainer.View>
      </div>
    </Snackbar>
  );
});

export default memo(KSnackBar);
