import React, { memo, useState } from 'react';

import EmailState from './EmailState';
import PasswordState from './PasswordState';

interface IProps {
  toggle: () => void;
}

const ForgotPassword = ({ toggle }: IProps) => {
  const [isPassword, setIsPassword] = useState(false);
  const [username, setUsername] = useState('');

  if (isPassword) {
    return <PasswordState toggle={toggle} username={username} />;
  }

  return <EmailState setIsPassword={setIsPassword} setUsername={setUsername} />;
};

export default memo(ForgotPassword);
