import { EventName, IS_TEACHER } from '@constants';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AuthContextProvider } from 'context/AuthContext';
import AppEvent from 'core/event';
import { useSignOut } from 'hooks';
import Layout from 'pages/Layout';
import Account from 'pages/Main/Account';
import Dashboard from 'pages/Main/Dashboard';
import Game from 'pages/Main/Game';
import StudentInGame from 'pages/Main/StudentInGame';
import TeacherInGame from 'pages/Main/TeacherInGame';
import StudentRequestForm from 'pages/StudentRequestForm';
import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';
import theme from 'theme';
import { useDimensionsChanged, useMount } from 'uikit';

import './App.css';

const teacherRouter = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'game',
        children: [
          {
            index: true,
            element: <Game key="new" />
          },
          {
            path: ':id',
            element: <Game key=":id" />
          }
        ]
      },
      {
        path: 'room/:roomId',
        element: <TeacherInGame />
      }
    ]
  },
  { path: '*', element: <Navigate to="/" /> }
]);

const studentRouter = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <StudentRequestForm />
      },
      {
        path: 'room/:roomId',
        element: <StudentInGame />
      }
    ]
  },
  { path: '*', element: <Navigate to="/" /> }
]);

function App() {
  useDimensionsChanged();

  const signOut = useSignOut();

  useMount(() => {
    AppEvent.subscribe(EventName.LOGOUT, () => {
      signOut.mutate();
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider>
        <Suspense fallback={null}>
          <RouterProvider router={IS_TEACHER ? teacherRouter : studentRouter} />
        </Suspense>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
