import { IStudentDto, ITeacherDto } from '@dto';
import { useUser } from 'hooks';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react';

type TypeAuthContext = {
  studentInfo: IStudentDto | undefined;
  teacherInfo: ITeacherDto | undefined;

  setStudentInfo: Dispatch<SetStateAction<IStudentDto>>;
  updateTeacherInfo: () => void;
};

const AuthContext = React.createContext<TypeAuthContext>({
  studentInfo: undefined,
  teacherInfo: undefined,

  setStudentInfo: () => {},
  updateTeacherInfo: () => {}
});

export const AuthContextProvider = (props: PropsWithChildren) => {
  const [studentInfo, setStudentInfo] = useState<IStudentDto | undefined>(
    undefined
  );

  const { user, refetch } = useUser();

  return (
    <AuthContext.Provider
      value={{
        studentInfo,
        teacherInfo: {
          ...user,
          id: `${user?.id}`
        },
        setStudentInfo,
        updateTeacherInfo: refetch
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
