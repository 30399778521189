import Grow, { GrowProps } from '@mui/material/Grow';
import Zoom, { ZoomProps } from '@mui/material/Zoom';

export const AlertTransitionConfigs = {
  Element: Grow,
  props: {
    timeout: 250
  } as GrowProps
};

export const PopupTransitionConfigs = {
  Element: Grow,
  props: {
    timeout: 500
  } as GrowProps
};

export const PopoverTransitionConfigs = {
  Element: Zoom,
  props: {
    timeout: 250
  } as ZoomProps
};
