import { useCallback, useLayoutEffect, useState } from 'react';

import { KDims } from '../constants';

const useDimensionsChanged = () => {
  const [width, setWidth] = useState(KDims.getWindowSize().width);
  const [height, setHeight] = useState(KDims.getWindowSize().height);

  const onChanged = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    KDims.updateWindowSize();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', onChanged);

    return () => {
      window.removeEventListener('resize', onChanged);
    };
  }, [onChanged]);

  return { width, height };
};

export default useDimensionsChanged;
