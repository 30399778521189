import { IconX } from '@assets/icons/components/IconX';
import { useCallback } from 'react';
import { KColors, KContainer, KTouchableProps, KViewProps } from 'uikit';
import { UIUtils } from 'utils';

export const usePopupIcon = () => {
  const xIcon = useCallback((data?: KTouchableProps) => {
    return (
      <KContainer.View className="absolute right-0 -top-[48px]">
        <KContainer.Touchable
          br="round"
          background={KColors.secondary.normal}
          padding="0.5rem"
          onPress={() => UIUtils.popup.dismiss()}
          {...data}
        >
          <IconX width={24} height={24} />
        </KContainer.Touchable>
      </KContainer.View>
    );
  }, []);

  const popupIcon = useCallback((data: KViewProps & { jsx: JSX.Element }) => {
    const { jsx, ...rest } = data;
    return (
      <KContainer.View
        br="round"
        background={KColors.white}
        padding="0.25rem"
        className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2"
      >
        <KContainer.View
          br="round"
          background="#e9f1ff"
          paddingV="1rem"
          paddingH="0.75rem"
          center
          size={65}
          {...rest}
        >
          {jsx}
        </KContainer.View>
      </KContainer.View>
    );
  }, []);

  return { xIcon, popupIcon };
};
