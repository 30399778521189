import common_en from './en.common.json';
import navigator_en from './en.navigator.json';

const en = {
  translation: {
    ...common_en,
    ...navigator_en
  }
};

const vi = {
  translation: {}
};

export { en, vi };
