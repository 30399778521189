import { MUTATION_KEYS } from '@constants';
import { useMutationState } from '@tanstack/react-query';
import { useUser } from 'hooks';
import React, { memo, useMemo, useState } from 'react';
import trans from 'translation';
import { KContainer, KTab } from 'uikit';

import AccountInformation from './AccountInformation';
import ChangePassword from './ChangePassword';

const Account = () => {
  const { user } = useUser();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        key: 'account',
        label: trans('common.account_information')
      },
      {
        key: 'changePassword',
        label: trans('common.change_password')
      }
    ];
  }, []);

  const passwordStatus = useMutationState({
    filters: { mutationKey: [MUTATION_KEYS.changePassword] },
    select: mutation => mutation.state.status
  });

  const accountStatus = useMutationState({
    filters: { mutationKey: [MUTATION_KEYS.updateAccount] },
    select: mutation => mutation.state.status
  });

  if (!user?.hasPassword) {
    return (
      <KContainer.View marginT="1rem" marginB="2rem" paddingH="1.5rem">
        <AccountInformation />
      </KContainer.View>
    );
  }

  return (
    <KContainer.View>
      <KTab.Solid
        tabs={tabs}
        isEqualWidth
        divider
        brBW={1}
        onChangeTab={setTabIndex}
        marginH="0.5rem"
        disabled={
          passwordStatus?.[0] === 'pending' || accountStatus?.[0] === 'pending'
        }
      />

      <KContainer.View marginV="2rem" paddingH="1.5rem">
        <KTab.Panel index={tabIndex} value={0}>
          <AccountInformation />
        </KTab.Panel>

        <KTab.Panel index={tabIndex} value={1}>
          <ChangePassword />
        </KTab.Panel>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(Account);
