import Box from '@mui/material/Box';
import omit from 'lodash/omit';
import React, { forwardRef, memo, useMemo } from 'react';

import { KViewProps } from '../types';
import { TypoHelper } from '../Typography';

const KView = forwardRef<HTMLDivElement, KViewProps>((props, ref) => {
  const { innerProps, innerStyle } = useMemo(() => {
    const { style, ...rest } = props;
    const { mStyle, mProps } = TypoHelper.destructPropsToStyle(rest);
    const mergeStyles = {
      ...style,
      ...mStyle.layout,
      ...mStyle.spacing,
      ...mStyle.styling
    };

    return {
      innerProps: mProps,
      innerStyle: mergeStyles
    };
  }, [props]);

  return (
    // @ts-ignore
    <Box
      component="div"
      {...omit(innerProps, 'onPress')}
      style={innerStyle}
      ref={ref}
    />
  );
});

export default memo(KView);
