import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import useTouchRipple from '@mui/material/useTouchRipple';
import debounce from 'lodash/debounce';
import React, {
  CSSProperties,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';

import KView from './View';

import { KColors } from '../../constants';
import { KTouchableProps } from '../types';
import { TypoHelper } from '../Typography';

const KTouchable = forwardRef<HTMLDivElement, KTouchableProps>((props, ref) => {
  const { onPress, avoidParentPress, noHover, ...otherProps } = props;

  const [isHover, setIsHover] = useState(false);

  const { innerProps, innerStyle } = useMemo(() => {
    const { style, ...rest } = otherProps;
    const { mStyle, mProps } = TypoHelper.destructPropsToStyle(rest);
    const mergeStyles = {
      ...(rest?.disabled || !onPress ? undefined : touchableStyle),
      ...style,
      ...mStyle.layout,
      ...mStyle.spacing,
      ...mStyle.styling,
      ...(isHover && !rest.disabled && !noHover
        ? {
            backgroundColor: otherProps.background
              ? KColors.hexToRgba(otherProps.background, 0.6)
              : 'rgba(0,0,0,0.04)'
          }
        : undefined)
    };

    return {
      innerProps: mProps,
      innerStyle: mergeStyles
    };
  }, [isHover, noHover, onPress, otherProps]);

  const onPressWrapper = useCallback(
    (e?: any) => {
      if (!innerProps?.disabled) {
        if (avoidParentPress) {
          (
            e as React.MouseEvent<HTMLDivElement, MouseEvent>
          )?.stopPropagation?.();
        }
        setIsHover(false);
        onPress?.(e);
      }
    },
    [avoidParentPress, innerProps?.disabled, onPress]
  );

  const { children, ...mProps } = innerProps;

  const rippleRef = React.useRef<any>(null);
  const { getRippleHandlers } = useTouchRipple({
    disabled: false,
    focusVisible: false,
    rippleRef
  });

  const { opacity } = mProps;

  return (
    <KView
      {...mProps}
      position="relative"
      onClick={debounce(onPressWrapper, 300, {
        leading: true,
        trailing: false
      })}
      onMouseEnter={() => setIsHover(true)}
      style={innerStyle}
      ref={ref}
      opacity={
        opacity !== null || opacity === undefined
          ? opacity
          : mProps.disabled
          ? 0.5
          : 1
      }
      {...getRippleHandlers()}
      onMouseLeave={e => {
        getRippleHandlers().onMouseLeave(e);
        setIsHover(false);
      }}
    >
      {children}
      {!noHover && <TouchRipple ref={rippleRef} center={false} />}
    </KView>
  );
});

export default memo(KTouchable);

const touchableStyle: CSSProperties = {
  cursor: 'pointer'
};
