import { IconX } from '@assets/icons/components/IconX';
import { Drawer, DrawerContent, DrawerHeader } from 'containers/Portal/Drawer';
import { memo } from 'react';
import { KLabel } from 'uikit';

export interface WithModal {
  open: () => void;
  dismiss: () => void;
}

export const PrivacySheet = memo(
  ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    return (
      <Drawer
        anchor="bottom"
        className="bg-gray-10"
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerHeader className="flex items-center gap-2 border-b border-gray-50 !px-4">
          <IconX onClick={onClose} />

          <KLabel.Text>Privacy sheet title</KLabel.Text>
        </DrawerHeader>
        <DrawerContent className="max-h-screen space-y-3 overflow-auto pb-[84px]">
          <KLabel.Text>Privacy sheet body</KLabel.Text>
        </DrawerContent>
      </Drawer>
    );
  }
);
