import { MUTATION_KEYS } from '@constants';
import AppValidation from 'core/validation';
import dayjs from 'dayjs';
import { useResolverForm, useUpdateAccount, useUser } from 'hooks';
import { usePopup } from 'hooks/usePopup';
import { set } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KForm,
  KInput,
  KLabel,
  KPicker
} from 'uikit';
import * as yup from 'yup';

interface IFormData {
  birthday: any;
  username: string;
  hasPassword?: boolean;
  newPassword?: string;
  reNewPassword?: string;
}

const schema = yup.object().shape({
  username: AppValidation.required(),
  birthday: AppValidation.requiredDate(),
  newPassword: yup.string().nullable(),
  // .when('hasPassword', {
  //   is: true,
  //   then: _schema => _schema,
  //   otherwise: _schema => _schema.required()
  // }),
  reNewPassword: yup
    .string()
    .nullable()
    // .when('hasPassword', {
    //   is: true,
    //   then: _schema => _schema,
    //   otherwise: _schema => _schema.required()
    // })
    .oneOf(
      [yup.ref('newPassword'), ''],
      trans('validation.password_not_matching')
    )
});

const AccountInformation = () => {
  const { user } = useUser();

  const { mutate, isPending } = useUpdateAccount();

  const initValues = useMemo(() => {
    return {
      username: user?.username ?? '',
      birthday: user?.birthday ? new Date(user.birthday * 1000) : new Date(),
      hasPassword: user?.hasPassword ?? true,
      newPassword: '',
      reNewPassword: ''
    };
  }, [user?.birthday, user?.hasPassword, user?.username]);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: initValues
    }
  });

  const { onPopupWithLogout } = usePopup();

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { username, birthday, hasPassword, newPassword } = data;

      const mParams = {
        id: user?.id,
        userName: username,
        birthday: dayjs(new Date(birthday)).unix()
      };

      if (!hasPassword && newPassword) {
        set(mParams, 'password', newPassword);
        onPopupWithLogout({
          title: trans('common.set_new_password'),
          onPress: () => mutate(mParams),
          mutationKey: [MUTATION_KEYS.updateAccount]
        });
      } else {
        mutate(mParams);
      }
    },
    [mutate, onPopupWithLogout, user?.id]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KLabel.Paragraph color={KColors.primary.normal} typo="TextXLgBold">
        {trans('common.account_information')}
      </KLabel.Paragraph>

      <KContainer.View marginT="1.25rem">
        <Controller
          name="username"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.TextField
                {...field}
                label={trans('common.username')}
                placeholder={trans('common.email_placeholder')}
                required
                message={error?.message}
              />
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="1.25rem">
        <Controller
          name="birthday"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KPicker.Date
                {...field}
                label={trans('common.birthday')}
                required
                message={error?.message}
                selected={field.value}
                maxDate={new Date()}
              />
            );
          }}
        />
      </KContainer.View>

      {!user?.hasPassword && (
        <>
          <KContainer.View marginT="1.25rem">
            <Controller
              name="newPassword"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.Password
                    {...field}
                    label={trans('common.new_password')}
                    placeholder={trans('placeholder.password')}
                  />
                );
              }}
            />
          </KContainer.View>

          <KContainer.View marginT="1.25rem">
            <Controller
              name="reNewPassword"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.Password
                    {...field}
                    label={trans('common.confirm_new_password')}
                    placeholder={trans('placeholder.password')}
                  />
                );
              }}
            />
          </KContainer.View>
        </>
      )}

      <KContainer.View marginT="2rem" row justifyContent="flex-end">
        <KButton.Solid
          title={trans('common.save_change')}
          type="submit"
          isLoading={isPending}
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(AccountInformation);
