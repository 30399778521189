import { useGameContext } from 'context/GameContext';
import React, { memo, useState } from 'react';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KImage,
  KInput,
  KLabel,
  KListItem
} from 'uikit';

const ReviewingTeam = () => {
  const { reviewingTeam, isLastReviewingTeam, setTeamLocalScore } =
    useGameContext();

  const [isAmend, setIsAmend] = useState(false);
  const [newScore, setNewScore] = useState('');

  return (
    <>
      <KImage.Base
        source="/images/inGame/make-the-decision.webp"
        alignItems
        width={200}
        height={215}
      />

      <KLabel.Paragraph marginV="0.75rem" textAlign typo="TextXLgNormal">
        {reviewingTeam?.name ?? ''}
      </KLabel.Paragraph>

      <KContainer.View
        row
        alignItems
        background="#01DFB7"
        paddingH="0.75rem"
        paddingV="0.5rem"
        br="2x"
      >
        <KLabel.Paragraph flex color={KColors.white} typo="TextMdNormal">
          {trans('common.student_counted_score')}
        </KLabel.Paragraph>

        <KContainer.View
          width={68}
          height={56}
          center
          background="#00A688"
          br="2x"
        >
          <KLabel.Text typo="H1" color={KColors.white}>
            {reviewingTeam?.finalScore ?? 100}
          </KLabel.Text>
        </KContainer.View>
      </KContainer.View>

      <KListItem.RadioGroup
        data={[
          {
            label: trans('common.happy_with_the_score'),
            checked: !isAmend,
            onChange: () => setIsAmend(false)
          },
          {
            label: trans('common.want_to_amend_the_score'),
            checked: isAmend,
            onChange: () => setIsAmend(true)
          }
        ]}
        marginT="1rem"
        noSpacing
      />

      {isAmend && (
        <KInput.TextField
          marginT="1rem"
          name={`score.${reviewingTeam?.id}`}
          placeholder={trans('placeholder.score')}
          value={newScore}
          onChange={e => setNewScore(e.target.value)}
        />
      )}

      <KButton.Solid
        marginT="1.5rem"
        width={'100%'}
        title={
          isLastReviewingTeam
            ? trans('common.finish_game')
            : trans('common.next_team')
        }
        onPress={() => {
          setTeamLocalScore(
            reviewingTeam?.id as string,
            newScore !== '' ? parseInt(newScore) : undefined
          );
          if (!isLastReviewingTeam) {
            setIsAmend(false);
          }
        }}
      />
    </>
  );
};

ReviewingTeam.displayName = 'Containers.InGame.Reviewing.TeamDecision';

export default memo(ReviewingTeam);
