import Error from './Error';
import Paragraph from './Paragraph';
import RichText from './RichText';
import Text from './Text';

export default class KLabel {
  static Text = Text;

  static Paragraph = Paragraph;

  static RichText = RichText;

  static Error = Error;
}
