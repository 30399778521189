import React, { memo } from 'react';
import { KContainer, KLabel } from 'uikit';

interface Props {
  isActive: boolean;
}

const SnapForm = ({ isActive }: Props) => {
  if (!isActive) {
    return null;
  }

  return (
    <KContainer.View>
      <KLabel.Text>SnapForm</KLabel.Text>
    </KContainer.View>
  );
};

export default memo(SnapForm);
