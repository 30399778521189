import dayjs from 'dayjs';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import 'dayjs/locale/en';
import 'dayjs/locale/vi';

import * as resources from './resources';

// @ts-ignore
const trans = (...args) => i18n.t(...args) as any;

const setLanguage = (language: string) => {
  i18n.changeLanguage(language);
  dayjs.locale(language);
};

const initLocalization = () => {
  const defaultLocale = 'en';
  i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: defaultLocale,
    lng: defaultLocale
  });
  setLanguage(defaultLocale);
};

const getLanguage = () => i18n.language;

export { i18n, initLocalization, setLanguage, getLanguage };

export default trans;
