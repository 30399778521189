import React, { forwardRef, memo } from 'react';

import TextField from './TextField';

import { KInputProps } from '../types';

const KInputNumber = forwardRef<HTMLInputElement, KInputProps>((props, ref) => {
  return (
    <TextField
      ref={ref as any}
      {...props}
      onKeyDown={(e: any) => {
        let key: any;
        // Handle paste or key combinations
        if (
          e.type === 'paste' ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey
        ) {
          e.preventDefault();
          return;
        }
        // Handle key press
        key = e.keyCode || e.which;
        // Number Pad
        if (key >= 96 && key <= 105) {
          key -= 48;
        }
        key = String.fromCharCode(key);

        const regex = /[0-9]|[/\b]/;
        if (!regex.test(key)) {
          e.returnValue = false;
          e?.preventDefault();
        }
      }}
    />
  );
});

export default memo(KInputNumber);
