import { IconFileUp } from '@assets/icons/components/IconFileUp';
import { IconFolderPlus } from '@assets/icons/components/IconFolderPlus';
import { IconTrash } from '@assets/icons/components/IconTrash';
import { MUTATION_KEYS } from '@constants';
import loadable from '@loadable/component';
import { Divider } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import FolderItem from 'containers/FolderItem';
import RoomItem from 'containers/RoomItem';
import { withMPadding } from 'hocs/withMPadding';
import { useCUDFolder, useFetchActiveRoomList, useUser } from 'hooks';
import { useBoolean } from 'hooks/core/useBoolean';
import { usePopup } from 'hooks/usePopup';
import isEmpty from 'lodash/isEmpty';
import React, {
  ComponentType,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KImage,
  KInput,
  KLabel,
  KSpacingValue
} from 'uikit';
import { UIUtils } from 'utils';

import { useDashboardGameData } from './helpers';

const ImportGame = loadable(() => import('./components/ImportGame'));
const NewFolder = loadable(() => import('containers/NewFolder'));

const AuthDashboard = () => {
  const { user } = useUser();
  const { username } = user || {};

  const { data = [] } = useDashboardGameData();

  const { data: roomList = [] } = useFetchActiveRoomList();

  const { value: isView, toggle } = useBoolean(true);

  const [folderIds, setFolderIds] = useState<number[]>([]);
  const [gameIds, setGameIds] = useState<number[]>([]);

  const {
    deleteRangeMutation: { mutate }
  } = useCUDFolder();

  const onCreateFolder = useCallback(() => {
    UIUtils.popup.open({
      title: trans('common.name_this_folder'),
      content: () => <NewFolder />
    });
  }, []);

  const { onRemovePopup } = usePopup();

  const onDeleteFolders = useCallback(() => {
    onRemovePopup({
      title: trans('common.delete_folder'),
      content:
        folderIds.length === 1 ? (
          <KContainer.View dp="flex" alignItems marginH="1.5rem">
            <KLabel.RichText
              textAlign
              richTextOptions={{
                i18nKey: 'common.delete_folder_message',
                components: {
                  bold: <KLabel.Text typo="TextNmBold" />
                },
                values: {
                  name: data.find(i => i.id === folderIds[0])?.label
                }
              }}
            />
          </KContainer.View>
        ) : (
          trans('common.delete_folders_message')
        ),
      buttons: [
        {
          variant: 'outline',
          title: trans('common.cancel')
        },
        {
          key: 'main',
          variant: 'solid',
          title: trans('common.delete'),
          onPress: () =>
            mutate(
              { folderIds, gameIds },
              {
                onSuccess: () => {
                  setGameIds([]);
                  setFolderIds([]);
                }
              }
            )
        }
      ]
    });
  }, [data, folderIds, gameIds, mutate, onRemovePopup]);

  const onImportGame = useCallback(() => {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: <IconFileUp stroke={KColors.primary.normal} fill={KColors.white} />
      },
      maxWidth: 'xs',
      title: trans('common.import_game'),
      content: <ImportGame />,
      mutationKey: [MUTATION_KEYS.importGame]
    });
  }, []);

  const renderActiveRooms = useMemo(() => {
    if (isEmpty(roomList)) {
      return null;
    }

    return (
      <>
        <KContainer.View
          row
          alignItems
          justifyContent="space-between"
          marginT="1.25rem"
          marginB="0.75rem"
        >
          <KLabel.Text typo="TextMdMedium">
            {trans('common.my_active_rooms')}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View gap="0.5rem" dp="flex">
          <TransitionGroup>
            {roomList?.map(i => {
              return (
                <Zoom key={`room_${i.id}`}>
                  <div>
                    <RoomItem item={i} />

                    <Divider color="#e2e8f0" />
                  </div>
                </Zoom>
              );
            })}
          </TransitionGroup>
        </KContainer.View>
      </>
    );
  }, [roomList]);

  const renderFolders = useMemo(() => {
    return (
      <>
        <KContainer.View
          row
          alignItems
          justifyContent="space-between"
          marginV="1.25rem"
        >
          <KLabel.Text typo="TextMdMedium">
            {trans('common.my_folders')}
          </KLabel.Text>

          <KContainer.View row alignItems gap="1rem">
            <KContainer.Touchable
              padding="0.5rem"
              br="round"
              background={KColors.secondary.normal}
              onPress={onCreateFolder}
            >
              <IconFolderPlus
                fill={KColors.white}
                stroke={KColors.primary.normal}
              />
            </KContainer.Touchable>

            <KContainer.Touchable
              padding="0.5rem"
              br="round"
              background={isView ? KColors.secondary.normal : '#FFCCD7'}
              onPress={() => {
                if (!isView && (folderIds.length > 0 || gameIds.length > 0)) {
                  onDeleteFolders();
                } else {
                  toggle();
                }
              }}
            >
              <IconTrash fill={KColors.white} stroke={KColors.primary.normal} />
            </KContainer.Touchable>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View gap="0.5rem" dp="flex">
          <TransitionGroup>
            {data?.map(i => {
              const isChecked = folderIds.includes(i.id);
              return (
                <Zoom key={`folder_${i.id}`}>
                  <div>
                    <FolderItem
                      item={i}
                      games={i.games}
                      isView={isView}
                      isChecked={isChecked}
                      setChecked={id => {
                        if (isChecked) {
                          setFolderIds(folderIds.filter(fId => fId !== id));
                        } else {
                          setFolderIds([...folderIds, id]);
                        }
                      }}
                      checkedGameIds={gameIds}
                      setGameChecked={setGameIds}
                    />
                    {!!i.id && (
                      <KContainer.View height={KSpacingValue['0.5rem']} />
                    )}
                  </div>
                </Zoom>
              );
            })}
          </TransitionGroup>
        </KContainer.View>
      </>
    );
  }, [
    data,
    folderIds,
    gameIds,
    isView,
    onCreateFolder,
    onDeleteFolders,
    toggle
  ]);

  return (
    <KContainer.View paddingT="1rem">
      <KContainer.View
        padding="1.25rem"
        paddingB="2.5rem"
        background={KColors.white}
        direction="column"
        center
        br="5x"
        brC="#f3f3f3"
        brW={1}
        style={styles.card}
      >
        <KImage.Avatar size="lg" />

        <KLabel.Paragraph marginT="0.5rem" typo="TextLgBold">
          {username}
        </KLabel.Paragraph>

        <KLabel.Paragraph
          marginT="0.25rem"
          typo="TextSmNormal"
          color={KColors.primary.normal}
        >
          {trans('common.teacher')}
        </KLabel.Paragraph>

        <KLabel.Paragraph typo="TextSmNormal" marginT="0.5rem" textAlign>
          {trans('common.welcome_message')}
        </KLabel.Paragraph>
      </KContainer.View>

      <KContainer.View row alignItems gap="1rem" marginV="1.25rem">
        <Link to={'game'} className="flex flex-grow">
          <KButton.Solid
            flex
            weight="normal"
            title={trans('common.create_game')}
          />
        </Link>

        <KButton.Outline
          flex
          weight="normal"
          title={trans('common.import_game')}
          background={KColors.transparent}
          onPress={onImportGame}
        />
      </KContainer.View>

      <KInput.Search />

      {renderActiveRooms}

      {renderFolders}
    </KContainer.View>
  );
};

export default memo(withMPadding(AuthDashboard));

(AuthDashboard as ComponentType<any>).displayName = 'TeacherDashboard';

const styles = {
  card: {
    boxShadow: '0 4px 160px 0 rgba(0, 0, 0, 0.05)'
  }
};
