import React, { memo, useMemo } from 'react';

import KCheckbox from './Checkbox';

import { KColors } from '../../constants';
import KContainer from '../Container';
import { KCheckboxGroupProps } from '../types';
import { TypoHelper } from '../Typography';

const KCheckboxGroup = (props: KCheckboxGroupProps) => {
  const { direction, data, noSpacing, ...rest } = props;

  const fontSize = useMemo(
    () => TypoHelper.getKFontSizeByTypo(rest.typo),
    [rest.typo]
  );

  if (direction === 'row') {
    return (
      <KContainer.View row alignItems>
        {data.map((i, idx) => {
          return (
            <KCheckbox
              key={`checkbox-${idx}`}
              {...i}
              {...rest}
              // @ts-ignore
              marginL={idx > 0 ? fontSize : undefined}
            />
          );
        })}
      </KContainer.View>
    );
  }

  return (
    <KContainer.View>
      {data.map((i, idx) => {
        return (
          <KCheckbox
            key={`checkbox-${idx}`}
            {...i}
            {...rest}
            // @ts-ignore
            paddingT={idx === 0 || noSpacing ? 0 : fontSize * 0.5}
            // @ts-ignore
            paddingB={idx === data.length - 1 || noSpacing ? 0 : fontSize * 0.5}
          />
        );
      })}
    </KContainer.View>
  );
};

KCheckboxGroup.defaultProps = {
  activeColor: KColors.primary.normal,
  typo: 'TextNmNormal',
  direction: 'column'
};

export default memo(KCheckboxGroup);
