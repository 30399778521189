import trans from 'translation';
import * as yup from 'yup';

class AppValidation {
  init = (additions: yup.ObjectShape = {}) => yup.object().shape(additions);

  required = (msg = trans('validation.required')) => yup.string().required(msg);
  requiredAny = () => yup.mixed().required();
  requiredDate = () =>
    yup.date().typeError(trans('validation.invalid_date')).required();
  requiredNum = () => yup.number().required();
  requiredBool = () => yup.bool().oneOf([true], 'Field must be checked');
}

export default new AppValidation();
