import { IRequestJoinRoomDto } from '@dto';
import PageHeader from 'containers/PageHeader';
import AppValidation from 'core/validation';
import { withMPadding } from 'hocs/withMPadding';
import { useRequestJoinRoom, useResolverForm } from 'hooks';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KInput } from 'uikit';
import { getUrlParam } from 'utils';
import * as yup from 'yup';

const schema = yup.object().shape({
  roomCode: AppValidation.required(),
  userName: AppValidation.required()
});

const StudentRequestForm = React.memo(() => {
  const roomCode = getUrlParam('code') || '8200';

  const { isPending, mutate } = useRequestJoinRoom();

  const methods = useResolverForm<IRequestJoinRoomDto>({
    schema,
    configs: {
      defaultValues: {
        userName: '',
        roomCode: roomCode || ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IRequestJoinRoomDto) => mutate(data),
    [mutate]
  );

  return (
    <>
      <KContainer.View>
        <PageHeader title={'Information'} showBackButton={false} />
      </KContainer.View>

      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <Controller
          name="roomCode"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.TextField
                {...field}
                label={trans('Game code')}
                placeholder={'Input game code'}
                message={error?.message}
              />
            );
          }}
        />

        <Controller
          name="userName"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KContainer.View marginT={'1.5rem'}>
                <KInput.TextField
                  {...field}
                  label={trans('Your name')}
                  placeholder={'Input your name'}
                  message={error?.message}
                />
              </KContainer.View>
            );
          }}
        />

        <KContainer.View marginT="1.75rem" row alignItems gap="1.5rem">
          <KButton.Outline
            flex
            title="Join"
            size="md"
            type="submit"
            isLoading={!!isPending}
          />
        </KContainer.View>
      </KForm>
    </>
  );
});

StudentRequestForm.displayName = 'Screen.StudentRequestForm';

export default memo(withMPadding(StudentRequestForm));
