import { IS_TEACHER } from '@constants';
import { useStudentInGameInfo } from 'hooks';
import React, { memo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KDims, KLabel } from 'uikit';

import { useGainLosePoint } from '../helpers';
import InGameImage from '../InGame.Image';

const TimesUp = () => {
  const { onGainLosePoint } = useGainLosePoint();

  const { isSupervisor } = useStudentInGameInfo();

  const isMobile = KDims.isMobile();

  const showButton = IS_TEACHER || isSupervisor;

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri="times-up.webp"
        background="#8571FF"
        justifyContent="center"
      >
        <KLabel.Paragraph
          typo="TextXLgBold"
          color={KColors.white}
          marginT="1.25rem"
        >
          {trans('common.times_up')}
        </KLabel.Paragraph>

        {!isMobile && showButton && (
          <KContainer.View
            row
            alignItems
            gap="1.5rem"
            width="100%"
            marginT="3rem"
          >
            <KButton.Outline
              flex
              title={trans('common.gain_or_lose_a_point')}
              onPress={onGainLosePoint}
            />

            {/* <KButton.Solid
              flex
              title={trans('common.resume_game')}
              onPress={onRequestResume}
            /> */}
          </KContainer.View>
        )}
      </InGameImage>

      {isMobile && showButton && (
        <>
          <KButton.Outline
            marginT="1.5rem"
            width={'100%'}
            title={trans('common.gain_or_lose_a_point')}
            onPress={onGainLosePoint}
          />

          {/* <KButton.Solid
            marginT="1.5rem"
            width={'100%'}
            title={trans('common.resume_game')}
            onPress={onRequestResume}
          /> */}
        </>
      )}
    </KContainer.View>
  );
};

TimesUp.displayName = 'Containers.InGame.TimesUp';

export default memo(TimesUp);
