import AppValidation from 'core/validation';
import { useForgotPassword, useResolverForm } from 'hooks';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KForm, KGrid, KInput } from 'uikit';
import { UIUtils } from 'utils';
import * as yup from 'yup';

interface IProps {
  toggle: () => void;
  username: string;
}

interface IFormData {
  newPassword: string;
  reNewPassword: string;
  otp: string;
}

const schema = yup.object().shape({
  newPassword: AppValidation.required(),
  reNewPassword: AppValidation.required().oneOf(
    [yup.ref('newPassword'), ''],
    trans('validation.password_not_matching')
  ),
  otp: AppValidation.required()
});

const PasswordState = ({ toggle, username }: IProps) => {
  const { mutate, isPending } = useForgotPassword();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      defaultValues: {
        newPassword: '',
        reNewPassword: '',
        otp: '123456'
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        userName: username,
        password: data.newPassword,
        otp: data.otp
      };
      mutate(mParams, {
        onSuccess: d => {
          if (d) {
            UIUtils.snackBar.open({
              message: 'Reset password successfully. Please login again.',
              status: 'success'
            });
            toggle();
          }
        }
      });
    },
    [mutate, toggle, username]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container columnSpacing={0} spacing={8}>
        <KGrid.Item xs={12}>
          <Controller
            name="newPassword"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Password
                  {...field}
                  label={trans('common.new_password')}
                  placeholder={trans('placeholder.password')}
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="reNewPassword"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Password
                  {...field}
                  label={trans('common.confirm_new_password')}
                  placeholder={trans('placeholder.password')}
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="otp"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('common.otp')}
                  placeholder={trans('placeholder.otp')}
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KButton.Solid
            title={trans('common.ok')}
            type="submit"
            // br="round"
            weight="normal"
            marginT="1.25rem"
            isLoading={isPending}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(PasswordState);
