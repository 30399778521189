import { TypographyOptions } from '@mui/material/styles/createTypography';

import { baseColors } from './colors';

import pxToRem from '../functions/pxToRem';

const baseProperties = {
  fontFamily: 'Roboto',

  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,

  fontSizeXXS: pxToRem(8),
  fontSizeXS: pxToRem(10),
  fontSizeSM: pxToRem(11),
  fontSizeNM: pxToRem(12),
  fontSizeMD: pxToRem(14),
  fontSizeLG: pxToRem(16),
  fontSizeXL: pxToRem(18),
  fontSize2XL: pxToRem(20),
  fontSize3XL: pxToRem(24),
  fontSize4XL: pxToRem(30)
};

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: baseColors.black,
  fontWeight: baseProperties.fontWeightBold
};

const typography: TypographyOptions = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,
  fontSize: 14,

  h1: {
    fontSize: pxToRem(36),
    lineHeight: 1.25,
    ...baseHeadingProperties
  },

  h2: {
    fontSize: pxToRem(30),
    lineHeight: 1.3,
    ...baseHeadingProperties
  },

  h3: {
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties
  },

  h4: {
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties
  },

  h5: {
    fontSize: pxToRem(18),
    lineHeight: 1.375,
    ...baseHeadingProperties
  },

  h6: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProperties
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeNM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.43
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.5
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeNM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.43
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeNM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.2,
    textTransform: 'uppercase'
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.2
  },

  overline: {
    fontFamily: baseProperties.fontFamily
  }
};

export { baseProperties };

export default typography;
