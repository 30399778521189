import AppValidation from 'core/validation';
import { useForgotPassword, useResolverForm } from 'hooks';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KForm, KGrid, KInput } from 'uikit';
import { UIUtils } from 'utils';
import * as yup from 'yup';

interface IProps {
  setIsPassword: (v: boolean) => void;
  setUsername: (v: string) => void;
}

interface IFormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: AppValidation.required()
});

const EmailState = ({ setIsPassword, setUsername }: IProps) => {
  const { mutate, isPending } = useForgotPassword();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      defaultValues: {
        email: ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        userName: data.email
      };
      mutate(mParams, {
        onSuccess: d => {
          if (d) {
            UIUtils.snackBar.open({
              message:
                'An email has been sent to your email address. Please check email to get OTP code to reset password',
              status: 'success'
            });
            setUsername(data.email);
            setIsPassword(true);
          }
        }
      });
    },
    [mutate, setIsPassword, setUsername]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container columnSpacing={0} spacing={8}>
        <KGrid.Item xs={12}>
          <Controller
            name="email"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('common.email')}
                  placeholder={trans('common.email_placeholder')}
                  inputType="email"
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KButton.Solid
            title={trans('common.ok')}
            type="submit"
            // br="round"
            weight="normal"
            marginT="1.25rem"
            isLoading={isPending}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(EmailState);
