import Autocomplete from './Autocomplete';
import Avatar from './Avatar';
import Currency from './Currency';
import Password from './Password';
import Search from './Search';
import Selection from './Selection';
import TextField from './TextField';
import TextFieldNumber from './TextFieldNumber';

export default class KInput {
  static TextField = TextField;

  static Autocomplete = Autocomplete;

  static Search = Search;

  static Currency = Currency;

  static Avatar = Avatar;

  static TextFieldNumber = TextFieldNumber;

  static Selection = Selection;

  static Password = Password;
}
