import { IconLogout } from '@assets/icons/components/IconLogout';
import { IconTrash } from '@assets/icons/components/IconTrash';
import { EventName } from '@constants';
import AppEvent from 'core/event';
import { useCallback } from 'react';
import trans from 'translation';
import { KColors, KPopupProps } from 'uikit';
import { UIUtils } from 'utils';

interface IUsePopupParams extends KPopupProps {
  onPress?: () => void;
}

export const usePopup = () => {
  const onRemovePopup = useCallback((params: KPopupProps) => {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        background: '#ffccd7',
        jsx: (
          <IconTrash
            width={44}
            height={44}
            stroke="#f77777"
            fill={KColors.white}
          />
        )
      },
      maxWidth: 'xs',
      ...params
    });
  }, []);

  const onLogoutPopup = useCallback(() => {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        background: '#ffccd7',
        jsx: <IconLogout width={44} height={44} stroke="#f77777" />
      },
      maxWidth: 'xs',
      title: trans('common.sign_out'),
      content: trans('common.sign_out_message'),
      buttons: [
        {
          variant: 'outline',
          title: trans('common.cancel')
        },
        {
          key: 'main',
          variant: 'solid',
          background: '#f77777',
          title: trans('common.sign_out'),
          onPress: () => AppEvent.dispatch(EventName.LOGOUT),
          withDismiss: true
        }
      ]
    });
  }, []);

  const onPopupWithLogout = useCallback(
    (params: Omit<IUsePopupParams, 'content'>) => {
      const { onPress, ...rest } = params;
      UIUtils.popup.open({
        withXIcon: true,
        headIcon: {
          background: '#ffccd7',
          jsx: <IconLogout width={44} height={44} stroke="#f77777" />
        },
        maxWidth: 'xs',
        content: trans('common.with_sign_out_message'),
        buttons: [
          {
            variant: 'outline',
            title: trans('common.cancel')
          },
          {
            key: 'main',
            variant: 'solid',
            title: trans('common.continue'),
            onPress: () => onPress?.()
          }
        ],
        ...rest
      });
    },
    []
  );

  return { onRemovePopup, onLogoutPopup, onPopupWithLogout };
};
