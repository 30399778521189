import { baseColors } from '../base/colors';

const iconButton = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: baseColors.transparent,
        color: baseColors.black
      },
      marginRight: 0
    },
    colorPrimary: {
      color: baseColors.primary
    },
    colorSecondary: {
      color: baseColors.secondary
    }
  }
};

export default iconButton;
