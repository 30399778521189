import { authRef } from '@constants';
import { memo, useCallback } from 'react';
import trans from 'translation';
import {
  APP_NAME,
  KButton,
  KColors,
  KContainer,
  KImage,
  KLabel,
  useDimensionsChanged
} from 'uikit';

const NoAuthDashboard = () => {
  const { width } = useDimensionsChanged();

  const _width = useCallback((w: number) => (w * width) / 1440, [width]);

  return (
    <>
      <KContainer.View sx={{ display: { md: 'none', xs: 'block' } }}>
        <KContainer.View
          paddingH="1.5rem"
          paddingV="2.25rem"
          background={KColors.secondary.normal}
          dp="flex"
          gap="1.5rem"
        >
          <KLabel.Paragraph typo="H2" color={KColors.primary.normal}>
            Paperless cutups for the modern classroom
          </KLabel.Paragraph>

          <KContainer.View br="4x">
            <KImage.Base source="/images/dashboard_1.webp" />
          </KContainer.View>

          <KLabel.Paragraph typo="TextXLgNormal">
            <KLabel.Text typo="TextXLgBold">{APP_NAME}</KLabel.Text> is a tool
            which brings your favourite cutups to the classroom without the
            paper!
          </KLabel.Paragraph>

          <KButton.Solid
            stretch
            title={trans('common.sign_up_for_free')}
            background="#ffde32"
            textColor={KColors.black}
            onPress={() => authRef.current?.open(1)}
          />
        </KContainer.View>

        <KContainer.View padding="1.5rem" row center>
          <KLabel.Text typo="H2" color={KColors.primary.normal} textAlign>
            Traditional Cutups delivered through mobiles include:
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View
          paddingH="1.5rem"
          paddingV="2.25rem"
          background={KColors.primary.normal}
          dp="flex"
          gap="3.75rem"
        >
          <KContainer.View dp="flex" center>
            <KLabel.Paragraph typo="H2" color={'#ffeda1'}>
              Taboo
            </KLabel.Paragraph>

            <KLabel.Paragraph
              textAlign
              marginT="1rem"
              typo="TextXLgNormal"
              color={KColors.white}
            >
              Describe a word to your teammates. Be careful not to use words on
              the banned list!
            </KLabel.Paragraph>
          </KContainer.View>

          <KContainer.View dp="flex" center>
            <KLabel.Paragraph typo="H2" color={'#ffeda1'}>
              Hotseat
            </KLabel.Paragraph>

            <KLabel.Paragraph
              textAlign
              marginT="1rem"
              typo="TextXLgNormal"
              color={KColors.white}
            >
              A frantic game where you shout out a word defined to you by your
              classmates. Be quicker than your opponent!
            </KLabel.Paragraph>
          </KContainer.View>

          <KContainer.View dp="flex" center>
            <KLabel.Paragraph typo="H2" color={'#ffeda1'}>
              Jumble
            </KLabel.Paragraph>

            <KLabel.Paragraph
              textAlign
              marginT="1rem"
              typo="TextXLgNormal"
              color={KColors.white}
            >
              Race against other teams to put the words, sentence or paragraph
              in the correct order. Make sure to look at all devices in your
              team!
            </KLabel.Paragraph>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View
          padding="1.5rem"
          background={KColors.white}
          dp="flex"
          gap="2.5rem"
        >
          <KContainer.View>
            <KLabel.Paragraph typo="Text3xLgMedium">
              Cut lesson prep time down by hours!
            </KLabel.Paragraph>

            <KContainer.View
              width={'100%'}
              height={232}
              center
              br="5x"
              background={'#eff5ff'}
              marginT="1.5rem"
            >
              <KImage.Base
                source="/images/dashboard_2.webp"
                width={155}
                height={142}
              />
            </KContainer.View>
          </KContainer.View>

          <KContainer.View>
            <KLabel.Paragraph typo="Text3xLgMedium">
              Increase engagement with live activities!
            </KLabel.Paragraph>

            <KContainer.View
              width={'100%'}
              height={232}
              center
              br="5x"
              background={'#eff5ff'}
              marginT="1.5rem"
            >
              <KImage.Base
                source="/images/dashboard_3.webp"
                width={285}
                height={198}
              />
            </KContainer.View>
          </KContainer.View>

          <KContainer.View>
            <KLabel.Paragraph typo="Text3xLgMedium">
              ‘Funify’ active learning!
            </KLabel.Paragraph>

            <KContainer.View
              width={'100%'}
              height={232}
              center
              br="5x"
              background={'#eff5ff'}
              marginT="1.5rem"
            >
              <KImage.Base
                source="/images/dashboard_4.webp"
                width={253}
                height={161}
              />
            </KContainer.View>
          </KContainer.View>

          <KButton.Solid
            stretch
            title={trans('common.sign_up_for_free')}
            background="#ffde32"
            textColor={KColors.black}
            onPress={() => authRef.current?.open(1)}
          />
        </KContainer.View>
      </KContainer.View>

      <KContainer.View sx={{ display: { xs: 'none', md: 'block' } }}>
        <KContainer.View
          paddingH="5.5rem"
          paddingV="4.5rem"
          background={KColors.secondary.normal}
          dp="flex"
          gap={150}
          row
          alignItems
        >
          <KContainer.View flex>
            <KLabel.Paragraph typo="H0" className="leading-[1.14]">
              Paperless cutups for the modern classroom
            </KLabel.Paragraph>

            <KLabel.Paragraph typo="Text3xLgNormal" marginV="3rem">
              <KLabel.Text typo="Text3xLgBold">{APP_NAME}</KLabel.Text> is a
              tool which brings your favourite cutups to the classroom without
              the paper!
            </KLabel.Paragraph>

            <KButton.Solid
              width={_width(331)}
              title={trans('common.sign_up_for_free')}
              background="#ffde32"
              textColor={KColors.black}
              onPress={() => authRef.current?.open(1)}
            />
          </KContainer.View>

          <KContainer.View br="4x" flex>
            <KImage.Base
              source="/images/dashboard_1.webp"
              width={_width(572)}
              height={_width(400)}
            />
          </KContainer.View>
        </KContainer.View>

        <KContainer.View
          marginT="3.75rem"
          marginH="5.5rem"
          dp="flex"
          justifyContent
        >
          <KLabel.Text typo="H1" color={KColors.primary.normal} textAlign>
            Traditional Cutups delivered through mobiles include:
          </KLabel.Text>

          <KContainer.View
            marginT="2.75rem"
            paddingH="3rem"
            paddingV="3rem"
            background={KColors.primary.normal}
            row
            alignItems="flex-start"
            gap="3rem"
            br="2x"
          >
            <KContainer.View dp="flex" center flex>
              <KLabel.Paragraph typo="H1" color={'#ffeda1'}>
                Taboo
              </KLabel.Paragraph>

              <KLabel.Paragraph
                textAlign
                marginT="1rem"
                typo="TextXLgNormal"
                color={KColors.white}
              >
                Describe a word to your teammates. Be careful not to use words
                on the banned list!
              </KLabel.Paragraph>
            </KContainer.View>

            <KContainer.View dp="flex" center flex>
              <KLabel.Paragraph typo="H1" color={'#ffeda1'}>
                Hotseat
              </KLabel.Paragraph>

              <KLabel.Paragraph
                textAlign
                marginT="1rem"
                typo="TextXLgNormal"
                color={KColors.white}
              >
                A frantic game where you shout out a word defined to you by your
                classmates. Be quicker than your opponent!
              </KLabel.Paragraph>
            </KContainer.View>

            <KContainer.View dp="flex" center flex>
              <KLabel.Paragraph typo="H1" color={'#ffeda1'}>
                Jumble
              </KLabel.Paragraph>

              <KLabel.Paragraph
                textAlign
                marginT="1rem"
                typo="TextXLgNormal"
                color={KColors.white}
              >
                Race against other teams to put the words, sentence or paragraph
                in the correct order. Make sure to look at all devices in your
                team!
              </KLabel.Paragraph>
            </KContainer.View>
          </KContainer.View>

          <KButton.Solid
            marginT="2.5rem"
            width={_width(331)}
            title={trans('common.sign_up_for_free')}
            background="#ffde32"
            textColor={KColors.black}
            onPress={() => authRef.current?.open(1)}
          />

          <KContainer.View marginV="6rem" dp="flex" gap="3.25rem">
            <KContainer.View row alignItems gap="3rem">
              <KContainer.View flex>
                <KLabel.Paragraph typo="H1" className="leading-[1.14]">
                  Cut lesson prep time down by hours!
                </KLabel.Paragraph>

                <KLabel.Paragraph marginT="1.5rem" typo="TextXLgNormal">
                  No more printing, cutting, organising! Prontoclass allows you
                  to create your favourite classroom activities without the
                  hassle, thanks to our mobile-friendly content.
                </KLabel.Paragraph>
              </KContainer.View>

              <KContainer.View
                width={_width(472)}
                height={_width(331)}
                center
                br="5x"
                background={'#eff5ff'}
                marginT="1.5rem"
                flex
              >
                <KImage.Base
                  source="/images/dashboard_2.webp"
                  width={_width(221)}
                  height={_width(203)}
                />
              </KContainer.View>
            </KContainer.View>

            <KContainer.View row alignItems gap="3rem">
              <KContainer.View
                width={_width(472)}
                height={_width(331)}
                center
                br="5x"
                background={'#eff5ff'}
                marginT="1.5rem"
                flex
              >
                <KImage.Base
                  source="/images/dashboard_3.webp"
                  width={_width(396)}
                  height={_width(275)}
                />
              </KContainer.View>

              <KContainer.View flex>
                <KLabel.Paragraph typo="H1" className="leading-[1.14]">
                  Increase engagement with live activities!
                </KLabel.Paragraph>

                <KLabel.Paragraph marginT="1.5rem" typo="TextXLgNormal">
                  Our interactive activities increase engagement though
                  competition and collaboration. Students work in teams to race
                  against each other in a live setting with results shown in
                  real time!
                </KLabel.Paragraph>
              </KContainer.View>
            </KContainer.View>

            <KContainer.View row alignItems gap="3rem">
              <KContainer.View flex>
                <KLabel.Paragraph typo="H1" className="leading-[1.14]">
                  ‘Funify’ active learning!
                </KLabel.Paragraph>

                <KLabel.Paragraph marginT="1.5rem" typo="TextXLgNormal">
                  Make learning a meaningful, memorable, & enjoyable experience,
                  regardless of age, content, or ability. Prontoclass lets you
                  tailor tasks, games, & activities to the needs of your
                  learners in a much more exciting & interactive manner!
                </KLabel.Paragraph>
              </KContainer.View>

              <KContainer.View
                width={_width(472)}
                height={_width(331)}
                center
                br="5x"
                background={'#eff5ff'}
                marginT="1.5rem"
                flex
              >
                <KImage.Base
                  source="/images/dashboard_4.webp"
                  width={_width(372)}
                  height={_width(224)}
                />
              </KContainer.View>
            </KContainer.View>
          </KContainer.View>
        </KContainer.View>
      </KContainer.View>
    </>
  );
};

export default memo(NoAuthDashboard);
