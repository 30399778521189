import { MediaHTMLAttributes } from 'react';

class AppAudio {
  private audio: HTMLAudioElement | null = null;
  private curSrc: string | null = null;
  private prevSrc: string | null = null;
  private timeoutInstance: NodeJS.Timer | null = null;

  // Create a singleton instance
  private static instance: AppAudio;

  private constructor() {}

  public static getInstance(): AppAudio {
    if (!AppAudio.instance) {
      AppAudio.instance = new AppAudio();
    }
    return AppAudio.instance;
  }

  public playSound(
    src: string,
    others?: Omit<MediaHTMLAttributes<HTMLAudioElement>, 'src'> & {
      isRevert?: boolean;
      keepRunningAfterSwitch?: boolean;
      cb?: () => void;
    }
  ) {
    if (src.includes('.mp3')) {
      // Stop the currently playing sound if it exists
      if (this.audio) {
        this.audio.pause();
      }

      this.prevSrc = this.curSrc;

      const { loop, isRevert, keepRunningAfterSwitch, cb } = others || {};

      // Create a new audio instance with loop and autoplay functionality
      this.audio = new Audio(src);

      if (loop && !keepRunningAfterSwitch) {
        this.audio.loop = true; // Set to loop
      }

      this.audio
        .play()
        .catch(error => {
          console.error('Failed to play audio:', error);

          if (isRevert && this.prevSrc) {
            this.playSound(this.prevSrc);
          }
        })
        .then(() => {
          if (keepRunningAfterSwitch) {
            if (this.timeoutInstance) {
              clearTimeout(this.timeoutInstance);
            }
            this.timeoutInstance = setTimeout(() => {
              if (this.prevSrc) {
                this.playSound(this.prevSrc);
              }
            }, 5000);
          }

          this.curSrc = src;
        })
        .finally(() => {
          cb?.();
        });
    }
  }

  public stopSound(src?: string) {
    if (this.audio && (src === undefined || this.curSrc === src)) {
      this.audio.pause();
      this.audio = null;
      this.curSrc = null;

      if (this.timeoutInstance) {
        clearTimeout(this.timeoutInstance);
        this.timeoutInstance = null;
      }
    }
  }
}

// Export a singleton instance
export default AppAudio.getInstance();
