import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import React, {
  memo,
  useMemo,
  CSSProperties,
  useCallback,
  useState,
  forwardRef,
  useRef
} from 'react';

import { KColors } from '../../constants';
import { useCombineRefs } from '../../hooks';
import KContainer from '../Container';
import KImage from '../Image';
import KLabel from '../Label';
import { KButtonProps, KSpacing, TypographyModifiers } from '../types';
import { KSpacingValue, TypoHelper } from '../Typography';

const KButtonBase = forwardRef<HTMLButtonElement, KButtonProps>(
  (props, ref) => {
    const {
      icon,
      assetIcon,
      iconJSX,
      iconAlignment = 'left',
      iconStyle,
      brW = 0,
      brC,
      brS,
      title,
      weight = 'normal',
      size,
      disabled,
      isLoading,
      stretch,
      background,
      tintColor,
      textColor,
      iconColor,
      hoverColor,
      enhanceStyle = {},
      hasShadow = false,
      hasHover = true,
      startIcon,
      endIcon,

      onPress,
      ...rest
    } = props;

    const innerRef = useRef<HTMLButtonElement>();
    const combinedRef = useCombineRefs<HTMLButtonElement>(ref, innerRef);

    const [isHover, setIsHover] = useState(false);

    const {
      height,
      icon: iconSize,
      spacing,
      text
    } = useMemo(() => {
      let result;
      switch (size) {
        case 'lg':
          result = {
            height: 58,
            icon: 24,
            text: 'TextLg',
            spacing: '0.5rem'
          };
          break;

        case 'md':
          result = {
            height: 50,
            icon: 22,
            text: 'TextMd',
            spacing: '0.5rem'
          };
          break;

        case 'sm':
          result = {
            height: 36,
            icon: 18,
            text: 'TextSm',
            spacing: '0.5rem'
          };
          break;

        case 'xs':
          result = {
            height: 28,
            icon: 16,
            text: 'TextXs',
            spacing: '0.5rem'
          };
          break;

        default:
          result = {
            height: 44,
            icon: 20,
            text: 'TextNm',
            spacing: '0.5rem'
          };
          break;
      }

      return { ...result, height: rest?.height ?? result.height };
    }, [rest?.height, size]);

    const mgDefault = useMemo(
      () => KSpacingValue[spacing as KSpacing],
      [spacing]
    );

    const textTypo = useMemo(() => {
      const postfix =
        weight === 'bold' ? 'Bold' : weight === 'normal' ? 'Normal' : 'Medium';
      return `${text}${postfix}` as TypographyModifiers;
    }, [weight, text]);

    const { innerStyle, innerProps } = useMemo(() => {
      const { mStyle: s, mProps: p } = TypoHelper.destructPropsToStyle(rest);
      const mergeStyles = {
        ...s.layout,
        ...s.spacing,
        ...s.styling
      };
      return { innerStyle: mergeStyles, innerProps: p };
    }, [rest]);

    const commonStyle: CSSProperties = useMemo(() => {
      const result = {
        backgroundColor:
          hasHover && isHover && !disabled && !isLoading
            ? hoverColor || KColors.hexToRgba(background as string, 0.6)
            : background,
        ...innerStyle
      };

      set(result, 'opacity', disabled ? 0.5 : 1);
      set(
        result,
        'alignSelf',
        stretch === 'left'
          ? 'flex-start'
          : stretch === 'right'
          ? 'flex-end'
          : stretch
          ? 'stretch'
          : 'center'
      );

      return result;
    }, [
      hasHover,
      isHover,
      disabled,
      isLoading,
      hoverColor,
      background,
      innerStyle,
      stretch
    ]);

    const styledButton: CSSProperties = useMemo(() => {
      const clone = { ...commonStyle };

      set(clone, 'height', height);

      if (!title) {
        set(clone, 'width', height);
      } else {
        set(clone, 'minWidth', height);
      }

      if (brW) {
        set(clone, 'borderColor', brC || tintColor);
        set(clone, 'borderWidth', brW);
        set(clone, 'borderStyle', brS || 'solid');
      }

      return clone;
    }, [commonStyle, height, title, brW, brC, tintColor, brS]);

    const renderIcon = useMemo(() => {
      if ((!icon && !assetIcon && !iconJSX) || isLoading) {
        return null;
      }

      const style = { color: iconColor || textColor };
      if (title) {
        if (iconAlignment === 'left') {
          set(style, 'marginRight', mgDefault);
          set(style, 'marginLeft', -mgDefault / 2);
        } else if (iconAlignment === 'right') {
          set(style, 'marginLeft', mgDefault);
          set(style, 'marginRight', -mgDefault / 2);
        }
      }

      if (assetIcon) {
        return <Icon style={{ ...style, ...iconStyle }}>{assetIcon}</Icon>;
      }

      if (icon) {
        return (
          <KImage.MuiIcon
            icon={icon}
            size={20}
            style={{ ...style, ...iconStyle }}
          />
        );
      }

      return iconJSX;
    }, [
      icon,
      assetIcon,
      iconJSX,
      isLoading,
      iconColor,
      textColor,
      title,
      iconAlignment,
      mgDefault,
      iconStyle
    ]);

    const onPressWrapper = useCallback(
      (e: any) => {
        if (!isLoading) {
          setIsHover(false);
          onPress?.(e);
        }
      },
      [onPress, isLoading]
    );

    const renderLoading = useMemo(() => {
      if (!isLoading) {
        return null;
      }

      return (
        <KContainer.View
          style={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress
            thickness={2}
            size={iconSize}
            sx={{ color: tintColor }}
          />
        </KContainer.View>
      );
    }, [iconSize, isLoading, tintColor]);

    return (
      <Button
        ref={combinedRef}
        {...pickBy(innerProps, v => v !== undefined)}
        disabled={disabled || isLoading}
        onClick={debounce(onPressWrapper, 300, {
          leading: true,
          trailing: false
        })}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          boxShadow:
            isLoading || disabled || !hasShadow
              ? 'none'
              : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          ...styledButton,
          ...enhanceStyle
        }}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {renderLoading}

        {iconAlignment === 'left' && renderIcon}
        {title && (
          <KLabel.Text
            typo={textTypo}
            color={isLoading ? KColors.transparent : textColor}
            style={{ lineHeight: 1.75 }}
            textTransform="none"
            flex
          >
            {title}
          </KLabel.Text>
        )}
        {iconAlignment === 'right' && renderIcon}
      </Button>
    );
  }
);

KButtonBase.defaultProps = {
  paddingH: '1rem',
  br: '2x',
  size: 'md'
};

const KButtonTransparent = memo(
  forwardRef<HTMLButtonElement, KButtonProps>((props, ref) => {
    const { kind = 'primary' } = props;

    const tintColor = KColors[kind]?.normal;

    const textColor = kind === 'normal' ? KColors.black : KColors[kind]?.normal;

    return (
      <KButtonBase
        ref={ref}
        hoverColor={KColors.hexToRgba('#114fbe', 0.1)}
        background={KColors.transparent}
        tintColor={tintColor}
        textColor={textColor}
        hasShadow={false}
        {...props}
      />
    );
  })
);

const KButtonSolid = memo(
  forwardRef<HTMLButtonElement, KButtonProps>((props, ref) => {
    const { kind = 'primary', revert } = props;

    // const backgroundColor = useMemo(() => {
    //   return disabled || isLoading
    //     ? KColors.opacity.grayDarker[10]
    //     : props.background || KColors[kind]?.normal;
    // }, [disabled, isLoading, kind, props.background]);

    const backgroundColor = props.background || KColors[kind]?.normal;

    // const tintColor = useMemo(() => {
    //   return disabled
    //     ? KColors.opacity.grayDarker[20]
    //     : props.tintColor || KColors.white;
    // }, [disabled, props.tintColor]);

    const tintColor = props.tintColor || KColors.white;

    // const textColor = useMemo(() => {
    //   return disabled
    //     ? KColors.opacity.grayDarker[20]
    //     : props.textColor || KColors.white;
    // }, [disabled, props.textColor]);

    const textColor = props.textColor || KColors.white;

    return (
      <KButtonBase
        ref={ref}
        {...props}
        background={revert ? tintColor : backgroundColor}
        tintColor={revert ? backgroundColor : tintColor}
        textColor={revert ? backgroundColor : textColor}
      />
    );
  })
);

const KButtonOutline = memo(
  forwardRef<HTMLButtonElement, KButtonProps>((props, ref) => {
    const { kind = 'primary', thickness = 'thin', background } = props;

    // const tintColor = useMemo(() => {
    //   return disabled
    //     ? KColors.opacity.grayDarker[10]
    //     : props.tintColor || KColors[kind]?.normal;
    // }, [disabled, kind, props.tintColor]);

    const tintColor = props.tintColor || KColors[kind]?.normal;

    // const textColor = useMemo(() => {
    //   return disabled
    //     ? KColors.opacity.grayDarker[20]
    //     : props.textColor || KColors.black;
    // }, [disabled, props.textColor]);

    // const iconColor = useMemo(() => {
    //   return disabled
    //     ? KColors.opacity.grayDarker[10]
    //     : kind === 'normal'
    //       ? KColors.normal.dark
    //       : KColors[kind]?.normal;
    // }, [disabled, kind]);

    const iconColor =
      kind === 'normal' ? KColors.normal.dark : KColors[kind]?.normal;

    return (
      <KButtonBase
        ref={ref}
        hasShadow={false}
        {...props}
        background={background || KColors.white}
        tintColor={tintColor}
        textColor={tintColor}
        iconColor={iconColor}
        hoverColor={KColors.normal.light}
        brW={thickness === 'thin' ? 1 : 2}
      />
    );
  })
);

const KButtonText = memo(
  forwardRef<HTMLButtonElement, KButtonProps>((props, ref) => {
    const { kind = 'primary', textColor } = props;

    // const textColor = useMemo(() => {
    //   return disabled ? KColors.opacity.grayDarker[20] : KColors[kind]?.normal;
    // }, [disabled, kind]);

    return (
      <KButtonBase
        ref={ref}
        {...props}
        background={KColors.transparent}
        textColor={textColor || KColors[kind]?.normal}
        paddingH={0}
        paddingV={0}
        hasShadow={false}
        hasHover={false}
        height={24}
      />
    );
  })
);

const KButtonDashed = memo(
  forwardRef<HTMLButtonElement, KButtonProps>((props, ref) => {
    return <KButtonTransparent ref={ref} {...props} brW={1} brS="dashed" />;
  })
);

export {
  KButtonBase,
  KButtonSolid,
  KButtonOutline,
  KButtonText,
  KButtonTransparent,
  KButtonDashed
};
