import PageHeader from 'containers/PageHeader';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import trans from 'translation';
import {
  KColors,
  KContainer,
  KLabel,
  KSpacingValue,
  useDidUpdate,
  useDimensionsChanged
} from 'uikit';

import AuthGoogleButton from './Auth.GoogleButton';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Register from './Register';

const AuthContent = forwardRef((props, ref) => {
  const { width } = useDimensionsChanged();

  const tabRef = useRef<HTMLDivElement>(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [isForgot, setIsForgot] = useState(false);

  useDidUpdate(() => {
    if (tabRef.current) {
      if (tabIndex === 0) {
        tabRef.current.style.transform = 'translateX(0%)';
      } else {
        tabRef.current.style.transform = 'translateX(calc(100% + 0.5rem))';
      }
    }
  }, [tabIndex]);

  useImperativeHandle(
    ref,
    () => ({
      setTabIndex,
      setIsForgot
    }),
    []
  );

  const renderAuthContent = useCallback(() => {
    return (
      <>
        <KContainer.View
          height={52}
          row
          alignItems
          padding="0.25rem"
          background={KColors.palette.gray.w25}
          br="2x"
          gap="0.5rem"
          position="relative"
          style={styles.tabs}
        >
          {[trans('common.login'), trans('common.register')].map((i, idx) => (
            <KContainer.Touchable
              key={`tab-index-${i}`}
              br="2x"
              flex
              center
              height="100%"
              onPress={() => setTabIndex(idx)}
              className={`z-2 transition-[border-width] duration-0 ${
                idx === tabIndex ? 'delay-250' : 'delay-0'
              }`}
              style={idx === tabIndex ? styles.tab : undefined}
            >
              <KLabel.Text
                typo="TextNmMedium"
                color={
                  idx === tabIndex ? KColors.grayLight.normal : KColors.black
                }
              >
                {i}
              </KLabel.Text>
            </KContainer.Touchable>
          ))}

          <KContainer.View
            ref={tabRef}
            position="absolute"
            dp="flex"
            height={44}
            width={'calc((100% - 1rem)/2)'}
            br="2x"
            className="z-1 ease-out duration-250"
            background={KColors.white}
          />
        </KContainer.View>

        <KContainer.View marginT="1.5rem">
          <AuthGoogleButton />

          <KContainer.View marginT="0.75rem" marginB="0.25rem" row center>
            <KContainer.View
              width={width - KSpacingValue['1.5rem'] * 2}
              height={1}
              background={KColors.border.light}
              position="absolute"
              className="z-0"
            />

            <KLabel.Text
              textAlign
              background={KColors.white}
              color={KColors.grayLight.normal}
              paddingH="0.5rem"
              className="z-10"
            >
              {trans('common.or_continue_with')}
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>

        {tabIndex === 0 ? (
          <Login onForgotPassword={() => setIsForgot(true)} />
        ) : (
          <Register />
        )}
      </>
    );
  }, [tabIndex, width]);

  const toggle = useCallback(() => {
    setIsForgot(!isForgot);
  }, [isForgot]);

  const renderForgotPassword = useCallback(() => {
    return (
      <>
        <PageHeader
          title={trans('common.forgot_password')}
          onClick={() => setIsForgot(false)}
        />

        <ForgotPassword toggle={toggle} />
      </>
    );
  }, [toggle]);

  const renderContent = useMemo(() => {
    return isForgot ? renderForgotPassword() : renderAuthContent();
  }, [isForgot, renderAuthContent, renderForgotPassword]);

  return <>{renderContent}</>;
});

export default memo(AuthContent);

const styles = {
  tabs: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1)'
  },
  tab: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
  }
};
