import { FOLDER_MAP_COLOR } from '@constants';
import React from 'react';
import { KContainer } from 'uikit';

import { IconFolderBottom } from '../components/IconFolderBottom';
import { IconFolderTop } from '../components/IconFolderTop';

interface IProps extends Omit<React.SVGProps<SVGElement>, 'width' | 'height'> {
  width?: number;
  height?: number;
}

const IconFolder = (props: IProps) => {
  const { color, width = 39, height = 31, ...rest } = props;

  return (
    <KContainer.View position="relative" width={width} height={height}>
      <KContainer.View className="absolute top-0">
        <IconFolderTop
          {...rest}
          width={width}
          height={(height * 11) / 31}
          fill={FOLDER_MAP_COLOR[color as string]}
        />
      </KContainer.View>
      <KContainer.View className="absolute top-[3px]">
        <IconFolderBottom
          {...rest}
          width={width}
          height={(height * 28) / 31}
          fill={color}
        />
      </KContainer.View>
    </KContainer.View>
  );
};

IconFolder.defaultProps = {
  color: '#567df4'
};

export { IconFolder };
