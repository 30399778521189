import { KTabSolid, KTabTransparent, KTabBase } from './Base';
import Outline from './Outline';
import Panel from './Panel';

export default class KTab {
  static Base = KTabBase;

  static Solid = KTabSolid;

  static Transparent = KTabTransparent;

  static Outline = Outline;

  static Panel = Panel;
}
