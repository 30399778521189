import { IconTrash } from '@assets/icons/components/IconTrash';
import {
  HOTSEAT_CONFIGS,
  E_RACE_MODES,
  E_GAME_MODES,
  GAMES
} from '@constants/gameConfigs';
import Stack from '@mui/material/Stack';
import FormItem from 'containers/FormItem';
import { useFetchGame, useResolverForm } from 'hooks';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import trans from 'translation';
import { KButton, KColors, KContainer, KInput, KLabel } from 'uikit';

import { IFormData, makeUniqueInputId, onLaunchGame, schema } from './helpers';

const { AVAILABLE_TEAMS, GAME_MODES, NUMBER_OF_STUDENTS, RACE_MODES } =
  HOTSEAT_CONFIGS;

interface Props {
  isActive: boolean;
}

const HotseatForm = ({ isActive }: Props) => {
  const { id } = useParams();

  const { data: _game } = useFetchGame(id);

  const game = useMemo(() => {
    return _game?.type === GAMES.HOTSEAT ? _game : undefined;
  }, [_game]);

  const methods = useResolverForm<IFormData>({
    schema: schema(),
    configs: {
      values: {
        id: game?.id,
        label: game?.label,
        numberOfTeams: game?.numberOfTeams ?? 2,
        numberOfStudents: game?.numberOfStudents ?? NUMBER_OF_STUDENTS[0].id,
        raceMode: game?.raceMode ?? E_RACE_MODES.RACE_NORMAL.id,
        gameMode: game?.gameMode ?? E_GAME_MODES.CHAOTIC.id,
        timeLimit: {
          min: game?.min ?? 0,
          sec: game?.sec ?? 0
        },
        words: (
          game?.contents ?? [
            {
              texts: [''],
              banneds: []
            }
          ]
        ).map(i => ({
          keyword: i.texts?.[0] ?? '',
          bannedWords: i.banneds
        })),
        isLaunchGame: true
      }
    }
  });

  const [_raceMode, _words] = useWatch({
    control: methods.control,
    name: ['raceMode', 'words']
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'words',
    keyName: 'hotseatWordId'
  });

  const onFormValid = useCallback((data: IFormData) => {
    const { raceMode, gameMode, words, timeLimit, ...rest } = data;

    const mParams = {
      type: GAMES.HOTSEAT,
      ...rest,
      ...timeLimit,
      roleTime: raceMode,
      rolePlay: gameMode,
      contents: words.map(w => ({
        texts: [w.keyword],
        banneds: []
      }))
    };

    onLaunchGame(mParams);
  }, []);

  const onMakeUniqueInputId = useCallback((name: string) => {
    return makeUniqueInputId(GAMES.HOTSEAT.toLowerCase())(name);
  }, []);

  const renderSettings = useCallback(() => {
    return (
      <>
        <KLabel.Paragraph typo="TextLgNormal" marginV={'1.25rem'} id="test">
          {trans('common.game_settings')}
        </KLabel.Paragraph>

        <KContainer.View marginT="1.25rem">
          <Controller
            name="numberOfTeams"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={`${trans('common.maximum_number_of_teams')} ${trans(
                    'common.only_n_teams',
                    { n: 2 }
                  )}`}
                  options={AVAILABLE_TEAMS}
                  disabled
                />
              );
            }}
          />
        </KContainer.View>

        <KContainer.View marginT="1.25rem">
          <Controller
            name="numberOfStudents"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={trans('common.maximum_member_of_teams')}
                  options={NUMBER_OF_STUDENTS}
                />
              );
            }}
          />
        </KContainer.View>

        <KContainer.View marginT="1.25rem">
          <Controller
            name="raceMode"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={trans('common.timing')}
                  options={RACE_MODES}
                />
              );
            }}
          />
        </KContainer.View>

        {_raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id && (
          <FormItem
            label={trans('common.set_limit_time')}
            jsx={
              <KContainer.View row alignItems>
                <Controller
                  name="timeLimit.min"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextFieldNumber
                        {...field}
                        name={onMakeUniqueInputId(field.name)}
                        placeholder={trans('placeholder.min')}
                        sx={{ width: 100 }}
                      />
                    );
                  }}
                />

                <KLabel.Text marginH={'0.75rem'} typo="TextLgMedium">
                  :
                </KLabel.Text>

                <Controller
                  name="timeLimit.sec"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextFieldNumber
                        {...field}
                        name={onMakeUniqueInputId(field.name)}
                        placeholder={trans('placeholder.sec')}
                        sx={{ width: 100 }}
                      />
                    );
                  }}
                />
              </KContainer.View>
            }
          />
        )}

        <KContainer.View marginT="1.25rem">
          <Controller
            name="gameMode"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={trans('common.game_mode')}
                  options={GAME_MODES}
                />
              );
            }}
          />
        </KContainer.View>
      </>
    );
  }, [methods.control, _raceMode, onMakeUniqueInputId]);

  const renderContents = useCallback(() => {
    return (
      <>
        <KLabel.Paragraph
          typo="TextLgNormal"
          marginB={'1.25rem'}
          marginT={'2rem'}
        >
          {trans('common.game_content')}
        </KLabel.Paragraph>

        {fields.map((d, idx) => {
          return (
            <KContainer.View key={d.hotseatWordId} marginB="1.5rem">
              <KContainer.View row alignItems>
                <Controller
                  name={`words.${idx}.keyword`}
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        name={onMakeUniqueInputId(field.name)}
                        placeholder={trans('placeholder.keyword')}
                        label={`${trans('common.word')} ${idx + 1}`}
                        message={error?.message}
                      />
                    );
                  }}
                />

                {_words.length > 1 ? (
                  <KContainer.Touchable
                    size={36}
                    center
                    background={KColors.secondary.normal}
                    br="round"
                    marginL="0.75rem"
                    onPress={() => remove(idx)}
                  >
                    <IconTrash
                      width={18}
                      height={18}
                      fill={KColors.white}
                      stroke={KColors.primary.normal}
                    />
                  </KContainer.Touchable>
                ) : (
                  <KContainer.View size={48} />
                )}
              </KContainer.View>
            </KContainer.View>
          );
        })}

        <KButton.Dashed
          title={`+ ${trans('common.add_more')}`}
          width={'100%'}
          onPress={() =>
            append({
              keyword: '',
              bannedWords: []
            })
          }
        />

        <Stack direction={'row'} spacing={3} sx={{ marginY: 6 }}>
          <KButton.Solid
            flex
            title={trans('common.launch_game')}
            onPress={e => {
              methods.setValue('isLaunchGame', true);
              methods.handleSubmit(onFormValid)(e);
            }}
          />

          <KButton.Outline
            flex
            title={trans('common.save_to_a_folder')}
            onPress={e => {
              methods.setValue('isLaunchGame', false);
              methods.handleSubmit(onFormValid)(e);
            }}
          />
        </Stack>
      </>
    );
  }, [
    fields,
    _words.length,
    methods,
    onMakeUniqueInputId,
    remove,
    append,
    onFormValid
  ]);

  return (
    <KContainer.View style={{ display: isActive ? undefined : 'none' }}>
      {renderSettings()}
      {renderContents()}
    </KContainer.View>
  );
};

export default memo(HotseatForm);
