import { alertRef, popupRef, popperRef, snackBarRef } from '@constants';
import { memo } from 'react';

import Alert from './Alert';
import Popper from './Popper';
import Popup from './Popup';
import SnackBar from './SnackBar';

export const Portal = memo(() => {
  return (
    <>
      <Alert ref={alertRef} />
      <Popup ref={popupRef} />
      <Popper ref={popperRef} />
      <SnackBar ref={snackBarRef} />
    </>
  );
});
