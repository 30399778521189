import { useUser } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { KContainer } from 'uikit';

const withAuth = (WrappedComponent: any) => {
  const WithAuth = (props: any) => {
    const navigate = useNavigate();

    const { user } = useUser();

    useEffect(() => {
      if (!user?.id) {
        const path = window.location.pathname;
        if (!(path === '' || path === '/')) {
          navigate('/');
        }
      }
    }, [navigate, user?.id]);

    return (
      <KContainer.View>
        <WrappedComponent {...props} />
      </KContainer.View>
    );
  };

  WithAuth.displayName = `withAuth(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return WithAuth;
};

export { withAuth };
