import { IS_TEACHER } from '@constants';
import React, { memo } from 'react';

import StudentPlaying from './StudentPlaying';
import TeacherPlaying from './TeacherPlaying';

const Playing = () => {
  if (IS_TEACHER) {
    return <TeacherPlaying />;
  }
  return <StudentPlaying />;
};

Playing.displayName = 'Containers.InGame.Playing';

export default memo(Playing);
