import { IconArrowBackward } from '@assets/icons/components/IconArrowBackward';
import IconButton from '@mui/material/IconButton';
import { Theme, SxProps } from '@mui/material/styles';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  KContainer,
  KLabel,
  SpacingModifiers,
  TypoType,
  generateElementId
} from 'uikit';

interface Props
  extends Pick<SpacingModifiers, 'marginT' | 'marginB' | 'marginV'> {
  id?: string;
  title: string;
  titleClassname?: string;
  titleColor?: string;
  titleTypo?: TypoType;
  iconClassname?: string;
  iconSx?: SxProps<Theme> | undefined;
  iconColor?: string;
  showBackButton?: boolean;
  onClick?: () => void;
}

const EL_TAG = 'page-header';

const PageHeader = ({
  id,
  title,
  titleClassname,
  titleColor = '#334155',
  titleTypo = 'TextLgNormal',
  iconClassname,
  iconColor = '#334155',
  iconSx,
  marginB,
  marginT,
  marginV,
  showBackButton = true,
  onClick
}: Props) => {
  const navigate = useNavigate();

  const onBack = useCallback(() => {
    if (onClick) {
      onClick();
      return;
    }
    navigate(-1);
  }, [navigate, onClick]);

  return (
    <KContainer.View
      id={generateElementId(id, EL_TAG)}
      row
      alignItems
      paddingV={'0.75rem'}
      marginB={marginB}
      marginT={marginT}
      marginV={marginV}
    >
      {showBackButton && (
        <IconButton sx={{ padding: 0, ...iconSx }} onClick={onBack}>
          <IconArrowBackward className={iconClassname} stroke={iconColor} />
        </IconButton>
      )}

      <KLabel.Text
        flex
        color={titleColor}
        textAlign
        marginH={'1rem'}
        className={titleClassname}
        typo={titleTypo}
      >
        {title}
      </KLabel.Text>

      {showBackButton && (
        <IconButton sx={{ padding: 0, ...iconSx, opacity: 0 }} disabled>
          <IconArrowBackward className={iconClassname} />
        </IconButton>
      )}
    </KContainer.View>
  );
};

(PageHeader as React.ComponentType<any>).displayName = EL_TAG;

export default memo(PageHeader);
