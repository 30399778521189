export default class KDims {
  static isSSR = () => typeof window === 'undefined';

  static width = !this.isSSR() ? window.innerWidth : 0;

  static height = !this.isSSR() ? window.innerHeight : 0;

  static updateWindowSize = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  };

  static getWindowSize = () => {
    if (this.isSSR()) {
      return { width: 0, height: 0 };
    }
    return { width: window.innerWidth, height: window.innerHeight };
  };

  static os = navigator.userAgentData?.platform ?? navigator.platform;

  static isMobile = () => {
    if (this.isSSR()) {
      return false;
    }

    return (
      navigator.userAgentData?.mobile ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      window.innerWidth < 768 ||
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0
    );
  };

  static isMac =
    ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(this.os) !==
    -1;

  static isIPad = ['iPad'].indexOf(this.os) !== -1;

  static isIPhone = this.isMobile() && ['iPhone'].indexOf(this.os) !== -1;

  static isAndroidPhone =
    this.isMobile() && /Android/.test(navigator.userAgent);

  static isWin = ['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(this.os) !== -1;

  static getOS = () => {
    if (this.isSSR()) {
      return '';
    }

    if (this.isAndroidPhone) {
      return 'Android';
    }

    if (this.isIPad) {
      return 'iPadOS';
    }

    if (this.isIPhone) {
      return 'iOS';
    }

    if (this.isMac) {
      return 'MacOS';
    }

    if (this.isWin) {
      return 'Windows';
    }
  };

  static getIOSVersion = () => {
    if (this.isSSR()) {
      return undefined;
    }

    // if current device is working on iOS, return the version as number,
    // otherwise return undefined
    if (this.isIPhone) {
      const versionInfo: RegExpMatchArray | null = navigator.userAgent.match(
        /iPhone OS ([0-9]+([_][0-9]*)?)/gi // since the userAgent for iPhone shows 'iPhone OS 15_5'
      );

      if (versionInfo !== null) {
        return parseFloat(versionInfo[0].replace('_', '.').split(' ')[2]);
      }
    }

    return undefined;
  };

  static iOSVersion = this.getIOSVersion();

  static isIOSFrom13 = this.iOSVersion ? this.iOSVersion >= 13 : false;

  static FORM_ITEM_HEIGHT = 50;
}
