import { IconCheck } from '@assets/icons/components/IconCheck';
import { IconCoinNumber } from '@assets/icons/components/IconCoinNumber';
import { useGameContext } from 'context/GameContext';
import AppAudio from 'core/audio';
import { useCallback } from 'react';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

export const useSubmitGainLosePoint = () => {
  const { onRequestUpdateScore } = useGameContext();

  const onSubmit = useCallback(
    (pt: number) => {
      AppAudio.playSound(
        `/sounds/${pt > 0 ? 'gain_point' : 'lose_point'}.mp3`,
        {
          cb: () => onRequestUpdateScore(pt)
        }
      );
    },
    [onRequestUpdateScore]
  );

  const onSubmitGainLosePoint = useCallback(
    (pt: number) => {
      UIUtils.popup.dismiss();
      UIUtils.popup.open({
        withXIcon: true,
        headIcon: {
          jsx: (
            <IconCheck
              stroke={KColors.primary.normal}
              fill={KColors.transparent}
              width={23}
              height={(23 * 14) / 15}
            />
          )
        },
        maxWidth: 'xs',
        title: trans('common.confirmation'),
        content: trans('common.submit_gain_lose_message'),
        buttons: [
          {
            variant: 'outline',
            title: trans('common.cancel')
          },
          {
            key: 'main',
            variant: 'solid',
            title: trans('common.yes'),
            onPress: () => onSubmit(pt),
            withDismiss: true
          }
        ]
      });
    },
    [onSubmit]
  );

  return { onSubmitGainLosePoint };
};

export const useGainLosePoint = () => {
  const { onSubmitGainLosePoint } = useSubmitGainLosePoint();

  const onGainLosePoint = useCallback(() => {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconCoinNumber
            stroke={KColors.primary.normal}
            fill={KColors.transparent}
          />
        )
      },
      maxWidth: 'xs',
      title: trans('common.gain_lose_a_point'),
      content: trans('common.gain_lose_message'),
      buttons: [
        {
          key: 'main',
          variant: 'outline',
          title: `+ ${trans('common.gain')}`,
          onPress: () => onSubmitGainLosePoint(1)
        },
        {
          key: 'main',
          variant: 'outline',
          title: `- ${trans('common.lose')}`,
          onPress: () => onSubmitGainLosePoint(-1)
        }
      ]
    });
  }, [onSubmitGainLosePoint]);

  return { onGainLosePoint };
};
