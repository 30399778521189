import { MUTATION_KEYS } from '@constants';
import AppValidation from 'core/validation';
import { useChangePassword, useResolverForm } from 'hooks';
import { usePopup } from 'hooks/usePopup';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KColors, KContainer, KForm, KInput, KLabel } from 'uikit';
import * as yup from 'yup';

interface IFormData {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
}

const schema = yup.object().shape({
  currentPassword: AppValidation.required(),
  newPassword: AppValidation.required(),
  reNewPassword: AppValidation.required().oneOf(
    [yup.ref('newPassword'), ''],
    trans('validation.password_not_matching')
  )
});

const ChangePassword = () => {
  const { mutate, isPending } = useChangePassword();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      defaultValues: {
        currentPassword: '',
        newPassword: '',
        reNewPassword: ''
      }
    }
  });

  const { onPopupWithLogout } = usePopup();

  const onFormValid = useCallback(
    (data: IFormData) => {
      onPopupWithLogout({
        title: trans('common.change_password'),
        onPress: () => mutate(data),
        mutationKey: [MUTATION_KEYS.changePassword]
      });
    },
    [mutate, onPopupWithLogout]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KLabel.Paragraph color={KColors.primary.normal} typo="TextXLgBold">
        {trans('common.change_password')}
      </KLabel.Paragraph>

      <KContainer.View marginT="1.25rem">
        <Controller
          name="currentPassword"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.Password
                {...field}
                label={trans('common.current_password')}
                placeholder={trans('placeholder.password')}
                required
                message={error?.message}
              />
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="1.25rem">
        <Controller
          name="newPassword"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.Password
                {...field}
                label={trans('common.new_password')}
                placeholder={trans('placeholder.password')}
                required
                message={error?.message}
              />
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="1.25rem">
        <Controller
          name="reNewPassword"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.Password
                {...field}
                label={trans('common.confirm_new_password')}
                placeholder={trans('placeholder.password')}
                required
                message={error?.message}
              />
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="2rem" row justifyContent="flex-end">
        <KButton.Solid
          title={trans('common.save_change')}
          type="submit"
          isLoading={isPending}
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(ChangePassword);
