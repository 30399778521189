/* eslint-disable no-unused-vars */
import { useDebounce } from '@dwarvesf/react-hooks';
import RemoveCircleOutlineOutlined from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams
} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';

import KTextField from './TextField';

import { KPaging } from '../../constants';
import { useAPIContext } from '../../context/api';
import {
  useCombineRefs,
  useCancelablePromise,
  useDidUpdate
} from '../../hooks';
import KButton from '../Button';
import KContainer from '../Container';
import { KInputProps } from '../types';
import { generateElementId } from '../Typography';

const useStyles = makeStyles({
  inputRoot: {
    paddingRight: '30px !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    paddingLeft: '0 !important'
  },
  input: {
    minWidth: '20px !important',
    padding: '0.75rem !important'
  },

  listbox: {
    fontSize: 14
  },
  endAdornment: {
    top: 'calc(50% - 13px) !important'
  },
  noOptions: {
    fontSize: '14px'
  }
});

interface KAutocompleteProps
  extends Omit<
    AutocompleteProps<
      HTMLInputElement,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'renderInput' | 'options'
  > {
  apiURL?: string;
  apiParams?: any;
  isInitFetch?: boolean;
  disabled?: boolean;
  onChange?: any;
  multiple?: boolean;

  hasAddNew?: boolean;
  addNewKey?: string;
  onAddNew?: () => void;
  addNewURL?: string;

  hasEdit?: boolean;
  onEdit?: (v?: any) => void;
  editURL?: string;

  label?: string;
  placeholder?: string;
  value?: any;
  name?: string;
  id?: string;

  inputProps?: KInputProps;
  options?: any;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const EL_TAG = 'autocomplete';

const KAutocomplete = forwardRef((props: KAutocompleteProps, ref) => {
  const classes = useStyles();

  const {
    label,
    placeholder,
    value,
    options: mOptions,
    apiURL,
    apiParams,
    isInitFetch = true,
    disabled,
    onChange,
    multiple = false,
    hasAddNew = false,
    addNewKey = 'name',
    onAddNew,
    addNewURL,

    hasEdit = false,
    editURL,
    onEdit,

    clearIcon,
    inputProps,
    ...rest
  } = props;

  const [keyword, setKeyword] = useState('');
  const [options, setOptions] = useState(mOptions || []);
  const [isLoading, setIsLoading] = useState(false);

  const debounceKeyword = useDebounce(keyword, 800);

  const mounted = useRef<boolean>();
  const inputRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombineRefs<HTMLInputElement>(ref, inputRef);

  const { apiInstance } = useAPIContext();

  const { promise, handleNewPromise } = useCancelablePromise();

  useDidUpdate(() => {
    if (!isEmpty(mOptions)) {
      setOptions(mOptions);
    }
  }, [JSON.stringify(mOptions)]);

  const fetchData = useCallback(
    async (kw: string = '') => {
      if (apiURL && !apiURL.includes(':') && !disabled && !mOptions) {
        setIsLoading(true);
        handleNewPromise(
          apiInstance({
            url: apiURL,
            body: {
              page: KPaging.page,
              size: KPaging.pageSize,
              status: 'ACTIVE',
              keyword: kw,
              ...(apiParams || {})
            },
            showToast: false
          })
        );
        const { data } = await promise.current;
        if (data) {
          setOptions(data?.data ?? data);
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      apiInstance,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(apiParams),
      apiURL,
      disabled,
      handleNewPromise,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(mOptions),
      promise
    ]
  );

  useEffect(() => {
    if (!mounted.current && !isInitFetch) {
      mounted.current = true;
    } else {
      fetchData(debounceKeyword);
    }
  }, [debounceKeyword, fetchData, isInitFetch]);

  const onClear = useCallback(() => {
    setKeyword('');
    onChange?.(multiple ? [] : null);
  }, [onChange, multiple]);

  useImperativeHandle(ref, () => ({
    clear: onClear
  }));

  const _onChange = useCallback(
    (v: any) => {
      if (
        v?.id === 'addNew' ||
        (Array.isArray(v) && v.some(i => i.id === 'addNew'))
      ) {
        onAddNew ? onAddNew() : window.open(`/admin/${addNewURL}`, '_blank');
      } else {
        onChange(v);
      }
    },
    [addNewURL, onAddNew, onChange]
  );

  const _onEdit = useCallback(() => {
    onEdit
      ? onEdit(value)
      : window.open(`/admin/${editURL}/${value?.id}`, '_blank');
  }, [editURL, onEdit, value]);

  const finalOptions = useMemo(() => {
    if (hasAddNew) {
      return [{ id: 'addNew', [addNewKey]: 'Add New' }, ...options];
    }
    return options;
  }, [addNewKey, hasAddNew, options]);

  const renderLoading = useMemo(() => {
    return (
      <KContainer.View dp="flex" center width={24} height={24}>
        <CircularProgress color="inherit" size={14} />
      </KContainer.View>
    );
  }, []);

  return (
    <Autocomplete
      classes={{
        inputRoot: classes.inputRoot,
        input: classes.input,
        listbox: classes.listbox,
        endAdornment: classes.endAdornment,
        noOptions: classes.noOptions,
        loading: classes.noOptions
      }}
      fullWidth
      openOnFocus
      // autoComplete={false}
      isOptionEqualToValue={(o: any, v: any) => {
        const _o = typeof o === 'string' ? o : o.id;
        const _v = typeof v === 'string' ? v : v.id;
        return _o === _v;
      }}
      // getOptionLabel={(o: any) => o.name ?? ''}
      onChange={(_, v) => _onChange(v)}
      onInputChange={(_, v) => setKeyword(v)}
      disableClearable
      // filterSelectedOptions={multiple}
      size="small"
      disabled={disabled}
      {...rest}
      multiple={multiple}
      id={generateElementId(rest.id || rest.name, EL_TAG)}
      options={finalOptions as any}
      value={value ?? (multiple ? [] : null)}
      // blurOnSelect
      ChipProps={{
        deleteIcon: <RemoveCircleOutlineOutlined sx={{ color: 'red' }} />
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((i: any, index) => {
          // eslint-disable-next-line react/jsx-key
          return <Chip label={i} {...getTagProps({ index })} />;
        });
      }}
      renderInput={params => {
        return (
          <KTextField
            {...params}
            {...inputProps}
            ref={combinedRef}
            label={label || ''}
            name={rest.name || ''}
            placeholder={disabled ? undefined : placeholder || 'Search'}
            InputProps={{
              ...(inputProps?.InputProps ?? {}),
              ...(params?.InputProps ?? {}),
              endAdornment: (
                <KContainer.View row alignItems>
                  {value && clearIcon && !isLoading && !disabled && (
                    <KButton.Icon icon="Close" onPress={onClear} size="sm" />
                  )}

                  {hasEdit && value && !isLoading && !multiple && (
                    <KButton.Icon icon="Edit" onPress={_onEdit} size="sm" />
                  )}

                  {isLoading && !disabled && renderLoading}

                  {!disabled && params.InputProps.endAdornment}
                </KContainer.View>
              )
            }}
          />
        );
      }}
    />
  );
});

(Autocomplete as React.ComponentType<any>).displayName = EL_TAG;

export default memo(KAutocomplete);
