import { ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from '@constants';
import { IGameDto, IRoomDto } from '@dto';
import { IGameParams, IMoveGameParams } from '@request-dto';
import APIManager from '@services';
import set from 'lodash/set';
import { useNavigate } from 'react-router-dom';
import { UIUtils } from 'utils';

import { useUser } from '../auth';
import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';
import { useCreateRoom } from '../room';

export const useFetchGameList = () => {
  const { user } = useUser();

  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.gameList],
    queryFn: () =>
      APIManager.request({
        url: ENDPOINTS.gameSetup('GetAll')
      }),
    enabled: !!user,
    initialData: [],
    placeholderData: []
  });
};

export const useFetchGame = (id?: string | number) => {
  const { user } = useUser();

  return useQueryEnhancer<IGameDto>({
    queryKey: [QUERY_KEYS.gameList, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.gameSetup('Get'),
        body: {
          id
        }
      });

      if (res.data) {
        const { roleTime, rolePlay, ...data } = res.data;
        const newData = {
          ...data,
          raceMode: roleTime,
          gameMode: rolePlay
        };
        set(res, 'data', newData);
      }

      return res;
    },
    enabled: !!user && !!id,
    initialData: [],
    placeholderData: []
  });
};

export const useCUDGame = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.gameSetup(),
    invalidateQueryKeys: [[QUERY_KEYS.gameList]]
  });
};

export const useMoveGame = () => {
  return useMutationEnhancer<any, IMoveGameParams>({
    mutationFn: mParams =>
      APIManager.request({
        url: ENDPOINTS.gameSetup('MoveFolder'),
        method: 'POST',
        body: mParams,
        showToast: true
      }),
    mutationKey: [MUTATION_KEYS.moveGame],
    invalidateQueryKeys: [[QUERY_KEYS.gameList]],
    onSuccess: data => {
      if (data.success) {
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useImportGame = () => {
  return useMutationEnhancer<any, string>({
    mutationFn: code =>
      APIManager.request({
        url: ENDPOINTS.gameSetup('Import'),
        method: 'POST',
        body: {
          code
        },
        showToast: true
      }),
    mutationKey: [MUTATION_KEYS.importGame],
    invalidateQueryKeys: [[QUERY_KEYS.gameList]],
    onSuccess: data => {
      if (data.success) {
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useDuplicateGame = () => {
  return useMutationEnhancer<any, string>({
    mutationFn: code =>
      APIManager.request({
        url: ENDPOINTS.gameSetup('Import'),
        method: 'POST',
        body: {
          code
        }
        // showToast: true
      }),
    mutationKey: [MUTATION_KEYS.duplicateGame],
    invalidateQueryKeys: [[QUERY_KEYS.gameList]],
    onSuccess: data => {
      if (data.success) {
        UIUtils.snackBar.open({
          message: 'Duplicate game successfully',
          status: 'success'
        });
      }
    }
  });
};

export const useLaunchGame = () => {
  const {
    createMutation: { mutateAsync: createAsync },
    updateMutation: { mutateAsync: updateAsync }
  } = useCUDGame();

  const { mutateAsync: mutateRoom } = useCreateRoom();

  const navigate = useNavigate();

  return useMutationEnhancer<IRoomDto | undefined, IGameParams>({
    mutationKey: [MUTATION_KEYS.launchGame],
    mutationFn: async mParams => {
      const { isLaunchGame, ...rest } = mParams;
      const mutateAsync = rest?.id && isLaunchGame ? updateAsync : createAsync;
      const { data: game } = await mutateAsync({
        ...rest,
        showToast: !isLaunchGame
      });

      if (isLaunchGame && (game?.id || rest?.id)) {
        const room = await mutateRoom(game?.id || rest?.id);
        return room;
      }

      return undefined;
    },
    onSuccess: d => {
      UIUtils.popup.dismiss();
      if (d?.id) {
        navigate(`/room/${d?.id}`);
      }
    },
    invalidateQueryKeys: [[QUERY_KEYS.gameList], [QUERY_KEYS.activeRoomList]]
  });
};
