import { IconMoreVertical } from '@assets/icons/components/IconMoreVertical';
import { MUTATION_KEYS } from '@constants';
import { IRoomDto } from '@dto';
import { useTerminateRoom } from 'hooks';
import { usePopup } from 'hooks/usePopup';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KLabel,
  KViewProps,
  generateElementId
} from 'uikit';
import { UIUtils } from 'utils';

interface IProps extends KViewProps {
  item: IRoomDto;
}

const EL_TAG = 'room-item';

const RoomItem = ({ item, ...rest }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const onJoinRoom = useCallback(() => {
    UIUtils.popper.dismiss();
    navigate(`room/${item.id}`);
  }, [item.id, navigate]);

  const { mutate } = useTerminateRoom();

  const { onRemovePopup } = usePopup();

  const onTerminateRoom = useCallback(() => {
    UIUtils.popper.dismiss();
    setTimeout(() => {
      onRemovePopup({
        title: trans('common.terminate_room'),
        content: trans('common.terminate_room_message'),
        mutationKey: [MUTATION_KEYS.terminateRoom],
        buttons: [
          {
            variant: 'outline',
            title: trans('common.cancel')
          },
          {
            key: 'main',
            variant: 'solid',
            title: trans('common.terminate'),
            onPress: () => mutate(item.id)
          }
        ]
      });
    }, 0);
  }, [item.id, mutate, onRemovePopup]);

  const eData = useMemo(() => {
    return [
      {
        title: 'common.join',
        onPress: onJoinRoom
      },
      {
        title: 'common.terminate',
        onPress: onTerminateRoom
      }
    ];
  }, [onJoinRoom, onTerminateRoom]);

  const onPress = useCallback(() => {
    UIUtils.popper.open({
      anchorEl: ref.current,
      placement: 'left-start',
      id: generateElementId(item.id, EL_TAG),
      content: () => (
        <KContainer.View
          background={KColors.white}
          brW={1}
          brC="#e2e8f0"
          br={'3x'}
          minW={150}
          paddingV="0.75rem"
          paddingH="0.5rem"
          dp="flex"
        >
          {eData.map(e => {
            return (
              <KButton.Transparent
                key={`room-${item.id}-${e.title}`}
                title={trans(e.title)}
                kind="gray"
                onPress={e.onPress}
                height={36}
                alignItems="flex-start"
                width="100%"
              />
            );
          })}
        </KContainer.View>
      ),
      sx: { zIndex: 1301 },
      touchOutsideToDismiss: true
    });
  }, [eData, item.id]);

  return (
    <KContainer.View
      paddingV="0.75rem"
      background={KColors.white}
      row
      alignItems
      id={generateElementId(item.id, EL_TAG)}
      {...rest}
    >
      {/* <IconGame stroke="#8b8c9f" fill="#e7e7e7" /> */}

      <KContainer.View flex marginR="1rem">
        <KContainer.View>
          <KLabel.Text numberOfLines={2} typo="TextXNmNormal">
            {item.id} - {item.gameSetup.label}
          </KLabel.Text>
        </KContainer.View>
      </KContainer.View>

      <KContainer.Touchable
        ref={ref}
        onPress={onPress}
        size={24}
        center
        br="round"
        avoidParentPress
      >
        <IconMoreVertical fill="#8B8C9F" />
      </KContainer.Touchable>
    </KContainer.View>
  );
};

RoomItem.displayName = EL_TAG;

export default memo(RoomItem);
