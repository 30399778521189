import React, { memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KButton from '../Button';
import KContainer from '../Container';
import KImage from '../Image';
import KLabel from '../Label';
import { KBreadcrumbsProps } from '../types';
import { KSpacingValue } from '../Typography';

const KBreadcrumbs = (props: KBreadcrumbsProps) => {
  const {
    hasBackIcon = false,
    onBack,
    title,
    breadcrumbs,
    rightNode,
    isFixed = true,
    ...rest
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onBackWrapper = useCallback(() => {
    if (onBack) {
      onBack();
    } else if (location.key === 'default') {
      navigate('../', { replace: true });
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, onBack]);

  const renderBackIcon = useMemo(() => {
    if (!hasBackIcon) {
      return null;
    }

    return (
      <KButton.Icon
        icon="ArrowBack"
        onPress={onBackWrapper}
        padding="0.5rem"
        marginR="0.25rem"
        enhanceStyle={styles.backIcon}
      />
    );
  }, [hasBackIcon, onBackWrapper]);

  const renderLeftNode = useMemo(() => {
    return (
      <KContainer.View flex row alignItems>
        {renderBackIcon}

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={!!breadcrumbs && Array.isArray(breadcrumbs)}
          >
            <KContainer.View row alignItems>
              {(breadcrumbs ?? [])
                .filter(i => i)
                .map<React.ReactNode>((i, idx) => (
                  <React.Fragment
                    key={
                      typeof i === 'string'
                        ? `breadcrumb-${i}`
                        : `breadcrumb-${(i as any)?.title}`
                    }
                  >
                    <KContainer.RenderWhen>
                      <KContainer.RenderWhen.If isTrue={typeof i === 'string'}>
                        <React.Fragment key={`breadcrumb-${i}`}>
                          <KContainer.RenderWhen>
                            <KContainer.RenderWhen.If isTrue={idx > 0}>
                              <KImage.MuiIcon
                                icon="ChevronRight"
                                marginH="0.25rem"
                              />
                            </KContainer.RenderWhen.If>
                          </KContainer.RenderWhen>

                          <KLabel.Text key={i as string} typo="TextLgMedium">
                            {i as string}
                          </KLabel.Text>
                        </React.Fragment>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue>
                        <React.Fragment key={`breadcrumb-${(i as any)?.title}`}>
                          <KContainer.RenderWhen>
                            <KContainer.RenderWhen.If isTrue={idx > 0}>
                              <KImage.MuiIcon
                                icon="ChevronRight"
                                marginH="0.25rem"
                              />
                            </KContainer.RenderWhen.If>
                          </KContainer.RenderWhen>

                          <KLabel.Text
                            key={(i as any)?.title}
                            typo="TextLgMedium"
                            onPress={(i as any)?.onPress}
                            underline={!!(i as any)?.onPress}
                          >
                            {(i as any)?.title}
                          </KLabel.Text>
                        </React.Fragment>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </React.Fragment>
                ))}
            </KContainer.View>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If isTrue={!breadcrumbs && !!title}>
            <KLabel.Text typo="TextLgMedium">{title}</KLabel.Text>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KContainer.View>
    );
  }, [breadcrumbs, renderBackIcon, title]);

  const renderRightNode = useMemo(() => {
    if (!rightNode) {
      return null;
    }

    if (rightNode.tools) {
      const { ref: toolsRef, ...toolsProps } = rightNode.tools;

      return (
        <KButton.Outline
          ref={toolsRef}
          {...toolsProps}
          title={'Tools'}
          icon="ArrowDropDown"
          iconAlignment="right"
        />
      );
    }

    if (rightNode.buttons) {
      return (
        <KContainer.View row alignItems>
          {rightNode.buttons.map((i, idx) => {
            const { props: bProps, type } = i;

            const Wrapper =
              type === 'text'
                ? KButton.Text
                : type === 'outline'
                ? KButton.Outline
                : type === 'transparent'
                ? KButton.Transparent
                : KButton.Solid;

            return (
              <Wrapper
                key={bProps.title}
                marginL={idx !== 0 ? '0.5rem' : 0}
                {...bProps}
              />
            );
          })}
        </KContainer.View>
      );
    }

    return rightNode.jsx ?? null;
  }, [rightNode]);

  const mStyle = useMemo(() => {
    const style: any = { ...rest.style };
    if (isFixed) {
      style.position = 'fixed';
      style.zIndex = 2;
    }
    return style;
  }, [isFixed, rest.style]);

  return (
    <>
      <KContainer.Card
        size="nm"
        brBL="2x"
        brBR="2x"
        height={50}
        width="inherit"
        paddingH="1rem"
        justifyContent
        marginT={0}
        {...rest}
        style={mStyle}
      >
        <KContainer.View row alignItems>
          {renderLeftNode}

          {renderRightNode}
        </KContainer.View>
      </KContainer.Card>

      <KContainer.RenderWhen>
        <KContainer.RenderWhen.If isTrue={isFixed}>
          <KContainer.View
            height={50 + KSpacingValue['0.5rem']}
            background="#f5f5f5"
            width="inherit"
          />
        </KContainer.RenderWhen.If>
      </KContainer.RenderWhen>
    </>
  );
};

export default memo(KBreadcrumbs);

const styles = {
  backIcon: {
    marginLeft: -KSpacingValue['0.5rem']
  }
};
